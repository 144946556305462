export const HEIGHT_FEET_VALIDATION = {
  min: 
  {
    value: 1,
    message: 'Feet height must be greater than 0'
  },
  max: {
    value: 9,
    message: 'Feet height must be less than 10'
  }
}

export const HEIGHT_INCHES_VALIDATION = {
  min: {
    value: 1,
    message: 'Inches height must be greater than 0'
  },
  max: {
    value: 11,
    message: 'Inches height must be less than 12'
  }
}

export const WEIGHT_VALIDATION = {
  min: {
    value: 1,
    message: 'Weight must be greater than 0'
  },
  max: {
    value: 999,
    message: 'Weight must be less than 1000'
  }
}