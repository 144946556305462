import { useContext } from "react"
import { DocumentUiProviderContext } from "."

const useDocumentUi = () => {
  const context = useContext(DocumentUiProviderContext)
  if (!context) {
    throw new Error('useDocumentUi must be used within a DocumentUiProvider')
  }
  return context
}

export default useDocumentUi