import BlueTopPage from '@/components/templates/BlueTopPage'
import legalMessages from '@/messages/legal'
import { ComponentTypes, NodeType } from '@/providers/DocumentUiProvider/types'
import useRenderJson from '@/providers/DocumentUiProvider/useRenderJson'
import { getPrivacyPolicyQuery } from '@/queries/legal'
import { formatDateString } from '@/utils/date'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'

const PrivacyPolicy = () => {
  const { data }  = useSuspenseQuery(getPrivacyPolicyQuery());

  const view = useRenderJson()

  const component: NodeType = { type: ComponentTypes.Markdown, children: data?.content } 

  return <BlueTopPage
    title={legalMessages.privacyPolicy}
    description={legalMessages.privacyPolicyDescription}
    >
      <VStack
        padding={'40px'}
        width={"100%"}
        bg={"white"}
        borderRadius={"10px"}
        boxShadow={"0px 10px 80px 0px #00417926"}
        borderWidth={0}
        alignItems={'flex-start'}
      >
        <HStack>
          <Text color='text.mediumBlue' fontWeight={'700'}>Effective: </Text>
        {/* TODO: EULA - update date to show activatedAt date */}
          <Text color='text.mediumBlue'>{formatDateString({ date: data.updatedAt })}</Text>
        </HStack>
        {data && view(component)}
      </VStack>
  </BlueTopPage>
}

export const Route = createFileRoute('/privacy-policy')({
  component: PrivacyPolicy,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getPrivacyPolicyQuery())
})
