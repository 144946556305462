import BlueTopPage from '@/components/templates/BlueTopPage'
import { useAuthContext } from '@/context/auth/useAuthContext'
import useAcceptMultipleDocuments from '@/hooks/legal/useAcceptMultipleDocuments'
import useToast from '@/hooks/useToast'
import legalMessages from '@/messages/legal'
import EulaUpdateAccordionItem from '@/modules/eula/EulaUpdateAccordionItem'
import { useAppStore } from '@/store/useAppStore'
import { isUndefined } from '@/utils'
import { Accordion, VStack, Button } from '@chakra-ui/react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

const EulaUpdate = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { isAdmin } = useAuthContext()
  const { legalAcceptanceStatuses } = useAppStore()
  const notAcceptedDocuments = legalAcceptanceStatuses?.filter((doc) => isUndefined(doc.acceptedAt))

  const { isPending, mutate } = useAcceptMultipleDocuments(() => {
    if(isAdmin){
      navigate({ to: '/organization/dashboard'})
      return
    }

    navigate({ to: '/user/dashboard'})
  })

  const onAcceptEula = () => {
    const ids = notAcceptedDocuments?.map((doc) => doc._id)

    if (!ids || ids.length === 0) {
      toast({
        title: 'Error',
        description: 'No documents to accept. Please reload.',
        status: 'error',
      })
      return
    }

    mutate(ids)
  }

  return <BlueTopPage title={legalMessages.updateEulaTitle} description={legalMessages.privacyPolicyDescription}>
    <VStack width={'100%'} marginBottom={'60px'}>
      <Accordion
        allowMultiple={false}
        allowToggle
        width={"100%"}
        gap={"40px"}
      >
        {notAcceptedDocuments?.map((doc) => (
          <EulaUpdateAccordionItem key={doc.s3Key} document={doc} />
        ))}
      </Accordion>
      <Button
        variant={'formSubmit'}
        onClick={onAcceptEula}
        isLoading={isPending}
      >
        I Accept
      </Button>
    </VStack>
  </BlueTopPage>
}

export const Route = createFileRoute('/_authenticated/_eula/eula/update')({
  component: EulaUpdate,
})
