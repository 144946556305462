import { Center } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'
import MyOmniaInfoIllustrations from '../MyOmniaInfoIllustrations'
import authMessages from '@/messages/auth'
import HeroBackgroundPage from '../HeroBackgroundPage'

interface Props {
  title?: string
}

const AuthenticationPage = ({ children, title = authMessages.signInTitle }: PropsWithChildren<Props>) => {
  return (
    <HeroBackgroundPage title={title}
      heroChildren={children}
    >
      <Center marginY={'100px'}>
        <MyOmniaInfoIllustrations variant="how_can_my_omnia_help" />
      </Center>
    </HeroBackgroundPage>
  )
}

export default AuthenticationPage