import DocumentPage from '@/components/organisms/DocumentPage';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { mixpanelEvents } from '@/constants/mixpanel';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { getWholenessScreenerQuery } from '@/queries/documents';
import { useAppStore } from '@/store/useAppStore';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useEffect } from 'react';

const OnboardingWholenessScreener = () => {
  const { trackEvent } = useTelemetry();

  const { data } = useSuspenseQuery(getWholenessScreenerQuery());

  useEffect(() => {
    trackEvent(mixpanelEvents.SCREENER_START, {
      screenerId: data._id,
      screenerSlug: data.slug,
    });
  }, []);

  return (
    <AppPageTemplate>
      <DocumentPage jsonConfig={data.data} />
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_onboarding/onboarding/wholeness-screener')({
  component: OnboardingWholenessScreener,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const {
      onboardingStatuses: {
        isUserOnboardingCompleted,
        isOrganizationOnboardingCompleted,
        isWholenessScreenerCompleted,
      },
    } = useAppStore.getState();

    const shouldRedirectToUserOrOrganizationOnboarding =
      (isOrgAdmin && !isOrganizationOnboardingCompleted) || !isUserOnboardingCompleted || isWholenessScreenerCompleted;
    if (shouldRedirectToUserOrOrganizationOnboarding) {
      if (!isOrganizationOnboardingCompleted && isOrgAdmin) {
        throw redirect({
          to: '/onboarding/organization',
        });
      }

      throw redirect({
        to: '/onboarding/user',
      });
    }
  },
  loader: async ({ context }) => context.queryClient.ensureQueryData(getWholenessScreenerQuery()),
});
