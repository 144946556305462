import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import { MODAL_QUESTION_FORM_KEY, NONE_OF_THE_ABOVE_LABEL } from '@/constants/stringVars';
import { VStack } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TDocumentComponentBase } from '../types';
import { TAssessmentModal } from '@/types/Assessment.types';

type TMultiSelectInputOption = {
  label: string;
  value: number;
};

type Props = TDocumentComponentBase & {
  options: TMultiSelectInputOption[];
  modal?: TAssessmentModal;
  reverseScoring: boolean;
  hasNoneOfTheAboveOption?: boolean;
};

const MultiSelectInput = ({ options, modal, reverseScoring, hasNoneOfTheAboveOption, name }: Props) => {
  const { control, setValue } = useFormContext();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<TMultiSelectInputOption[]>([]);

  const updateFormValue = useCallback(
    (newSelectedCheckboxes: TMultiSelectInputOption[]) => {
      // let totalScore = 0;

      // TODO: score calc needs more work... we should probably have it be done in a new hook similar to useAssessmentResult
      // reverseScoring means that leaving a checkbox BLANK gives you a BETTER score, so, in case of reverseScoring
      // the INITIAL total_score === maxCheckboxValueSum (with all the checkboxes UN-checked);
      // therefore, selecting a checkbox SUBTRACTS value from the total (i.e. maxCheckboxValueSum)
      // if (reverseScoring) {
      //   totalScore = maxCheckboxValueSum - selectedCheckboxes.length * options[0].value;
      // } else {
      //   totalScore = selectedCheckboxes.reduce((acc, curr) => (acc += curr.value), 0);
      // }

      const modalCondition = modal?.selectionCondition;
      let modalUrgencyValue = '';
      if (modalCondition) {
        if (
          (modalCondition === 'any' && newSelectedCheckboxes.length > 0) ||
          (modalCondition === 'all' && newSelectedCheckboxes.length === options.length)
        ) {
          modalUrgencyValue = modal.urgency;
        }
      }

      setValue(
        name,
        { value: newSelectedCheckboxes, [MODAL_QUESTION_FORM_KEY]: modalUrgencyValue },
        { shouldDirty: true },
      );
    },
    [modal, options],
  );

  const updateSelectedCheckboxes = useCallback(
    (clickedCheckbox: TMultiSelectInputOption) => {
      let newSelectedCheckboxes = [];
      const isCheckboxAlreadyChecked = selectedCheckboxes.find(
        (value: TMultiSelectInputOption) => value.label === clickedCheckbox.label,
      );
      if (isCheckboxAlreadyChecked) {
        newSelectedCheckboxes = selectedCheckboxes.filter((checkbox) => checkbox.label !== clickedCheckbox.label);
      } else {
        newSelectedCheckboxes = [...(selectedCheckboxes || []), clickedCheckbox];
      }

      setSelectedCheckboxes(newSelectedCheckboxes);
      updateFormValue(newSelectedCheckboxes);
    },
    [selectedCheckboxes, setValue],
  );

  // none-of-the-above option is NOT in the selectedCheckboxes
  const [isNoneOfTheAboveSelected, setIsNoneOfTheAboveSelected] = useState(false);

  const maxCheckboxValueSum = useMemo(
    () => options.reduce((acc, curr) => (acc += curr.value), 0),
    [options, reverseScoring],
  );

  const noneOfTheAboveOption: TMultiSelectInputOption = useMemo(
    () => ({ value: reverseScoring ? maxCheckboxValueSum : 0, label: NONE_OF_THE_ABOVE_LABEL }),
    [maxCheckboxValueSum],
  );

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={() => (
        <VStack flexWrap={'wrap'} gap={5}>
          {options.map((checkboxOption) => (
            <CheckBoxTile<TMultiSelectInputOption>
              key={checkboxOption.label}
              checkboxOption={checkboxOption}
              onClick={updateSelectedCheckboxes}
              isDisabled={isNoneOfTheAboveSelected}
              isChecked={
                selectedCheckboxes.length
                  ? !!selectedCheckboxes.find((value: TMultiSelectInputOption) => value.label === checkboxOption.label)
                  : false
              }
            />
          ))}

          {hasNoneOfTheAboveOption && (
            <CheckBoxTile<TMultiSelectInputOption>
              key={'none-of-the-above'}
              checkboxOption={noneOfTheAboveOption}
              onClick={() => {
                setIsNoneOfTheAboveSelected((prev) => !prev);
                updateFormValue([]);
              }}
              isChecked={isNoneOfTheAboveSelected}
              isDisabled={!!selectedCheckboxes.length}
            />
          )}
        </VStack>
      )}
    />
  );
};

export default MultiSelectInput;
