import svgIcons from '@/assets/svg/icons';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Center, HStack } from '@chakra-ui/react';
import { useRouter } from '@tanstack/react-router';
import CustomButton from '../CustomButton';

const GoBackLeftArrow = () => {
  const router = useRouter();

  return (
    <Center position={'absolute'} height={'50px'}>
      <HStack width={MAX_CONTAINER_WIDTH} paddingLeft={'24px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIcons.leftArrow}
          iconBeforeLabelSvgSize={15}
          labelHoverColor="primary.black"
          label="Go Back"
          borderHoverColor="transparent"
          onClick={router.history.back}
          style={{ paddingLeft: 0 }}
          labelStyle={{ marginBottom: 2, fontWeight: 700, fontSize: '16px' }}
        />
      </HStack>
    </Center>
  );
};

export default GoBackLeftArrow;
