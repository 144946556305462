import illustrations from "@/assets/img/png/ilustrations";
import { TIlustrationContent } from "..";
import { APP_NAME } from "@/constants/stringVars";

const howMyOmniaCanHelpContent: TIlustrationContent = {
  title: `How ${APP_NAME} can help?`,
  infoItems: [
    {
      title: "Wellness Report",
      description: "We will ask you some questions about how you feel.",
      iconPath: illustrations.wellnessReport
    },
    {
      title: "Useful Insights",
      description: "After you finish your intake we will show you a report of your current state of wellness.",
      iconPath: illustrations.headWithCog
    },
    {
      title: "Connect to Your Peer Support Team",
      description: "Quick, confidential access to your trained coworkers that can offer you support.", 
      iconPath: illustrations.headToHead
    },
    {
      title: "Qualified Therapists",
      description: "Qualified providers that are ready to support your mental health needs.", 
      iconPath: illustrations.nurse
    }
  ]
}

export default howMyOmniaCanHelpContent;