import Slider from '@/components/atoms/Slider'
import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'

type Props = {
  value: number;
  setValue: (_: number) => void;
  labelStart?: string;
  labelEnd?: string;
  inverted?: boolean;
}

const SliderExtended = ({ value, setValue,
  labelStart = "Not Good",
  labelEnd = "Excellent",
  inverted = false
 }: Props) => {

  return (
    <VStack
      maxWidth={'610px'}
      width={'100%'}
      backgroundColor={'extra.white'}
      boxShadow={'0px 8px 40px 0px #00417933'}
      borderRadius={'20px'}
      minHeight={'100px'}
      paddingY={"21px"}
      paddingX={"33px"}
    >
      {(labelStart || labelEnd) && (
        <HStack justifyContent={"space-between"} width={"100%"}>
          <Text fontSize={"14px"} color="text.darkBlue" lineHeight={"17px"} opacity={0.5}>
            {`${inverted ? labelEnd : labelStart}`}
          </Text>
          <Text fontSize={"14px"} color="text.darkBlue" opacity={0.5}>
            {`${inverted ? labelStart : labelEnd}`}
          </Text>
        </HStack>
      )}
      <Slider value={value} setValue={setValue} />
    </VStack>
  )
}

export default SliderExtended