import { Box } from '@chakra-ui/react';
import type { CSSProperties } from 'react';

const UserProfileBox = ({ children, style }: { children: React.ReactNode; style?: CSSProperties }) => {
  return (
    <Box
      height="270px"
      width="490px"
      padding={'40px'}
      paddingTop={'30px'}
      boxShadow="0px 10px 80px 0px #00417926"
      background="white"
      borderRadius={'10px'}
      style={style}
    >
      {children}
    </Box>
  );
};

export default UserProfileBox;
