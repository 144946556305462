import { type UseMutateFunction, useMutation } from '@tanstack/react-query';

import { deleteOwnAccount } from '@/services/api/requests/user';
import useToast from '../useToast';
import { TCustomAxiosError } from '@/services/api';

const useDeactivateOwnAccount = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, void, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      await deleteOwnAccount();
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: `Account Deactivation successful.`,
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Account Deactivation Error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export { useDeactivateOwnAccount };
