import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import UserProfileBox from './UserProfileBox';
import UserProfileBoxInfoPiece from './UserProfileBoxInfoPiece';

interface UserContactDetailsBoxProps {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  onClickEdit: () => void;
  hideEditButton?: boolean;
}

const UserContactDetailsBox = ({ email, firstName, lastName, phone, onClickEdit, hideEditButton = false }: UserContactDetailsBoxProps) => {
  return (
    <UserProfileBox style={{ height: '360px' }}>
      <HStack justifyContent={'space-between'}>
        <Text variant={'urbanistSemiBoldTitle'} marginBottom={'10px'}>
          Contact Details
        </Text>
       {!hideEditButton && <CustomButton
          onClick={onClickEdit}
          hoverBackgroundColor={'primary.100'}
          label="Edit"
          labelColor="text.darkBlue"
          style={{
            padding: 0,
            borderColor: colors.text.darkBlue,
            borderWidth: '2px',
          }}
          width={'110px'}
          height={'55px'}
          iconBeforeLabelJSX={
            <Image
              alt="edit-icon"
              src={iconsPng.pen}
              width={'12px'}
              height={'12px'}
              objectFit="cover"
              style={{ marginRight: '10px' }}
            />
          }
        />}
      </HStack>
      <VStack alignItems={'start'} gap={'20px'} marginTop={'20px'}>
        <UserProfileBoxInfoPiece title={'EMAIL ADDRESS'} text={email} />
        <UserProfileBoxInfoPiece title={'NAME'} text={`${firstName} ${lastName}`} />
        <UserProfileBoxInfoPiece title={'PHONE NUMBER'} text={phone} />
      </VStack>
    </UserProfileBox>
  );
};

export default UserContactDetailsBox;
