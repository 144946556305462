import ColorOverlay from '@/components/atoms/ColorOverlay';
import VideoPlayer from '@/components/atoms/VideoPlayer';
import { Box } from '@chakra-ui/react';
import React from 'react';

const HeroBackgroundVideo = () => {
  return (
    <Box width="100%" height={'100%'} zIndex={0}>
      <VideoPlayer />
      <ColorOverlay color1Alpha={0.6} color1={'#1A00B9'} height={'100vh'} />
      <ColorOverlay color1Alpha={0.3} color2Alpha={0} color1={'#000000'} height={'100vh'} />
    </Box>
  );
};

export default HeroBackgroundVideo;
