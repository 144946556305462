import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import defaultComponents from "./defaultComponents";
import Wrapper from "./parts/Wrapper";

interface Props {
  testId?: string;
  children: string | undefined | null;
}

/**
 * For now we only allow to pass styling props to the wrapper (not to the inner components)
 * But it is still possible to change the default styling of each 'part' from
 * the theme > components > Markdown
 */
const Markdown = ({ testId, children, ...props }: Props) => (
  <Wrapper {...props} data-test-id={`b99a408b-${testId}`}>
    <ReactMarkdown components={defaultComponents} rehypePlugins={[rehypeRaw]}>
      {children}
    </ReactMarkdown>
  </Wrapper>
);

export default Markdown;
