import { TEulaAcceptance, TRepresentativeFormData } from "@/modules/onboarding/organization/types";
import { TCustomAxiosError } from "@/services/api";
import { acceptedDocumentsGet, acceptEulaDocumentPost } from "@/services/api/requests/legal";
import { inviteUsersToOrganizationPost, myOrganizationGet, updateOrganizationByIdPut, uploadOrganizationLogoPost } from "@/services/api/requests/organization";
import { useAppStore } from "@/store/useAppStore";
import { TOrganization } from "@/types/Organization.types";
import { TRosterItemPerson } from "@/types/Roster.types";
import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

interface ISubmitOrganization {
  organizationId: string,
  formData: TRepresentativeFormData, 
  eulaAcceptance: TEulaAcceptance | null, 
  logoFile: File, 
  roster: TRosterItemPerson[] | null
}

const useSubmitOrganization = (onSuccessCallback?: (organization: TOrganization) => void) => {  
  const toast = useToast()

  const { isEulaAccepted: isEulaAcceptedInit, legalAcceptanceStatuses, setLegalAcceptanceStatuses } = useAppStore()


  const { isPending, mutate, isError} = useMutation({
    mutationFn: async ({ formData, logoFile, roster, organizationId, eulaAcceptance }: ISubmitOrganization) => {
      const promises = []

       
      const { isEulaAccepted: eulaCheckboxBoolean , ...restFormData} = formData

      const updateOrg = updateOrganizationByIdPut(organizationId, restFormData)
      promises.push(updateOrg)
            
      if(roster && roster.length > 0){
        const usersToInvite = roster.map((person) => ({
          email: person["Email"],
          phone: person["Phone"],
          firstName: person["First Name"],
          lastName: person["Last Name"],
        }));

        const inviteUsers = inviteUsersToOrganizationPost(usersToInvite)
        
        promises.push(inviteUsers)
      }

      if (!isEulaAcceptedInit && eulaCheckboxBoolean && eulaAcceptance !== null){
        const documentIds = legalAcceptanceStatuses?.map((document) => document._id) || []
        const promises = documentIds.map((documentId) => acceptEulaDocumentPost(documentId))
        promises.push(...promises)
      }

      const uploadLogo = uploadOrganizationLogoPost(logoFile)
      promises.push(uploadLogo)

      // TODO: EULA - add accept request
      await Promise.all(promises)

      const updatedOrg = await myOrganizationGet()

      const updatedLegalStatues = await acceptedDocumentsGet();
      setLegalAcceptanceStatuses(updatedLegalStatues)

      return { organization: updatedOrg }
    },
    onSuccess: ({ organization }) => {
      if(onSuccessCallback){
        onSuccessCallback(organization)
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Organization onboarding flow',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
    }
  })

  return {isPending, mutate, isError}

}

export default useSubmitOrganization;