import { acceptedDocumentsGet, acceptEulaDocumentPost } from "@/services/api/requests/legal";
import { useAppStore } from "@/store/useAppStore";
import { TLegalDocumentAcceptanceStatus } from "@/types/Legal.types";
import { useMutation } from "@tanstack/react-query";
import useToast from "../useToast";
import { TCustomAxiosError } from "@/services/api";

const useAcceptMultipleDocuments = (onSuccessCallback?: () => void) => {
  const toast = useToast()

  const { setLegalAcceptanceStatuses } = useAppStore()


  const { isPending, mutate } = useMutation({
    mutationFn: async (documentIds: string[]): Promise<TLegalDocumentAcceptanceStatus[]> => {
      const promises = documentIds.map((documentId) => acceptEulaDocumentPost(documentId))
      
      await Promise.all(promises)

      const updatedLegalStatues = await acceptedDocumentsGet();
      return updatedLegalStatues
    },
    onSuccess: (legalStatuses) => {
      setLegalAcceptanceStatuses(legalStatuses)

      toast({
        title: "Success",
        description: "You have accepted the legal documents",
        status: "success",
      })

      if(onSuccessCallback){
        onSuccessCallback()
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: "Error",
        description: error?.errors[0]?.message ?? error.toString(),
        status: "error",
      })
    }
  })

  return {isPending,mutate}
}

export default useAcceptMultipleDocuments;