import { HOUR_IN_MILISECONDS } from '@/constants';
import { TCustomAxiosResponse } from '@/services/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

interface IUsePaginationParams<T> {
  query: (page: number) => Promise<TCustomAxiosResponse<T>>;
  queryKey: string;
}

/**
 *
 * @param query - the query to be executed. Must receive `page: number` param and return TCustomAxiosResponse<T>
 * @returns isLoading: boolean; isSuccess: boolean; data: T | undefined; isError: boolean; error: Error | null; pagination: IPagination
 */
export const usePagination = <T>({ query, queryKey }: IUsePaginationParams<T>) => {
  const [page, setPage] = useState(1);

  const { isLoading, isSuccess, data, isError, error, refetch, isRefetching } = useQuery({
    queryKey: [queryKey, page],
    queryFn: async () => {
      const response = await query(page);
      return response;
    },
    staleTime: HOUR_IN_MILISECONDS,
    placeholderData: keepPreviousData,
  });

  const lastPage = data?.pagination?.totalPages ?? 1;

  const getFirstPage = () => setPage(1);
  const getLastPage = () => setPage(lastPage);
  const getNextPage = () => setPage((prev) => (prev < lastPage ? prev + 1 : lastPage));
  const getPreviousPage = () => setPage((prev) => (prev > 1 ? prev - 1 : 1));

  return {
    isLoading,
    isSuccess,
    data: data?.data,
    isError,
    error,
    refetch,
    isRefetching,
    pagination: {
      getFirstPage,
      getNextPage,
      getPreviousPage,
      getLastPage,
      totalPages: data?.pagination?.totalPages ?? 0,
      currentPage: page,
    },
  };
};
