import type { CSSProperties } from 'react';

import { HStack, Text, Checkbox } from '@chakra-ui/react';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';

type TMandatoryCheckboxField = {
  label: string;
};

interface Props<T> {
  checkboxOption: T;
  isChecked: boolean;
  onClick: (_: T) => void;
  isDisabled?: boolean;
  buttonStyle?: CSSProperties;
  noOfLines?: number;
}

const CheckBoxTile = <T extends TMandatoryCheckboxField>({
  checkboxOption,
  isChecked,
  onClick,
  isDisabled,
  noOfLines,
}: Props<T>) => {
  return (
    <HStack
      background={'extra.white'}
      boxShadow={isChecked ? '' : `0px 2px 8px 0px #00417926`}
      borderRadius={8}
      padding={'16px'}
      width={FORM_FIELD_WIDTH}
      onClick={(e) => {
        if (isDisabled) return;
        e.preventDefault(); // necessary to prevent double-event from clicking the checkbox
        onClick(checkboxOption);
      }}
      borderWidth={2}
      borderColor={isChecked ? 'primary.500' : 'transparent'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ boxShadow: isChecked || isDisabled ? '' : `0px 2px 16px 0px #00417926` }}
      gap={0}
    >
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        colorScheme="primary"
        backgroundColor={'background.darkGray'}
        borderColor={'background.darkGray'}
        borderRadius={'4px'}
        size={'lg'}
        justifyContent={'center'}
        alignItems={'center'}
      />
      <Text
        fontWeight={600}
        noOfLines={noOfLines}
        maxWidth={'240px'}
        textOverflow={'elipsis'}
        fontSize={'14px'}
        color={isChecked ? 'primary.500' : 'text.darkBlue'}
        marginLeft={'20px'}
      >
        {checkboxOption.label}
      </Text>
    </HStack>
  );
};

export default CheckBoxTile;
