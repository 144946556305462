import { Text, useStyles } from "@chakra-ui/react";

interface Props {
  node: { type: string; content: string }; // Replace with the actual structure of the node object
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Paragraph = ({ node, ...props }: Props) => {
  const styles = useStyles();

  return <Text sx={styles.paragraph} {...props} />;
};

export default Paragraph;
