import { VStack } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

const DocumentSlide = ({ children, ...restProps}: PropsWithChildren) => {
  return (
    <VStack
      className='embla__slide'
      {...restProps}
    >
      <VStack maxWidth={'620px'} paddingBottom={'40px'}>
        {children}
      </VStack>
    </VStack>
  )
}

export default DocumentSlide