import { lazy, Suspense } from 'react';
import { createRootRouteWithContext } from '@tanstack/react-router';
import type { QueryClient } from '@tanstack/react-query';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-router';
import { theme } from '@/theme';
import { TAuthContext } from '@/context/auth';
import { BACKGROUND_COLOR } from '@/constants/ui';
import NotFoundPage from '@/components/templates/NotFoundPage';
import isDebug from '@/utils/isDebug';

const TanStackRouterDevtools =
  import.meta.env.MODE === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  auth: TAuthContext | null;
}>()({
  component: RootComponent,
  notFoundComponent: NotFoundPage,
});

function RootComponent() {
  return (
    <ChakraProvider theme={theme}>
      <Flex backgroundColor={BACKGROUND_COLOR} justifyContent={'center'}>
        <Outlet />
      </Flex>
      {isDebug && <Suspense>
        <TanStackRouterDevtools />
      </Suspense>}
    </ChakraProvider>
  );
}
