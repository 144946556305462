import { addHours, format, FormatOptions } from 'date-fns'

export const formatDateString = ({ date, formatDateOptions }: { date: string, formatDateOptions?: FormatOptions }) => {
  const parsedDate = new Date(date)

  const formattedDate = format(parsedDate, 'MMMM dd, yyyy', formatDateOptions)
  return formattedDate
}

export const displayDateFromBeWithTimeZone = (date: string | Date | undefined, withTime?: boolean) => {
  if (date) {
    const birthDate = date ? new Date(date) : new Date();
    const timeZoneOffsetHours = birthDate.getTimezoneOffset() / 60;
    const birthDateWithTimezoneOffset = addHours(birthDate, timeZoneOffsetHours);
    return format(birthDateWithTimezoneOffset, withTime ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy');
  }
  return '';
}