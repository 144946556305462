import { useAppStore } from '@/store/useAppStore';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/_eula')({
  beforeLoad: async ({ context }) => {
    const isOrgAdmin = context.auth?.isAdmin;
    const { onboardingStatuses, isEulaAccepted } = useAppStore.getState()

    const isOnboardingCompleted = isEverythingSetup(onboardingStatuses, isOrgAdmin)

    // EULA can be accepted through onboarding
    if (isOnboardingCompleted && isEulaAccepted) {
      if (isOrgAdmin && isOnboardingCompleted) {
        throw redirect({
          to: '/organization/dashboard',
        });
      }

      throw redirect({
        to: '/user/dashboard',
      });
    }
  }
})
