const rootPath = '/img/png/illustrations'

const illustrations = {
  nurse: `${rootPath}/info/nurse.png`,
  headToHead: `${rootPath}/info/head-to-head.png`,
  headWithCog: `${rootPath}/info/head-with-cog.png`,
  cloudConnected: `${rootPath}/info/cloud-connect.png`,
  wellnessReport: `${rootPath}/info/wellness-report.png`,
  radientOrangeBg: `/img/radient-orange.png`,
}

export default illustrations;