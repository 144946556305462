import type { PropsWithChildren } from "react";

import {
  Box,
  StylesProvider,
  useMultiStyleConfig,
} from "@chakra-ui/react";

interface Props {
  size?: string;
  variant?: string;
  children: React.ReactNode;
}

const Wrapper = ({
  size,
  variant,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const styles = useMultiStyleConfig("Markdown", { size, variant });

  return (
    <Box __css={styles.wrapper} {...props}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};

export default Wrapper;
