import type { CSSProperties, PropsWithChildren } from "react";
import { useLayoutEffect } from "react";

import * as d3 from "d3";

import { Center } from "@chakra-ui/react";
import { CIRCLE_SVG_SIZE, CIRCLE_WIDTH, CIRCLES_SVG_DATA } from "@/constants/dimensions";

type CircleSvgData = { radius: number; opacity: number };

interface Props {
  circleSvgData?: CircleSvgData[];
  circleSvgSize?: number;
  circleWidth?: number;
  style?: CSSProperties;
}

const DrawnCirclesBackground = ({
  circleSvgData = CIRCLES_SVG_DATA,
  circleSvgSize = CIRCLE_SVG_SIZE,
  circleWidth = CIRCLE_WIDTH,
  style,
  children,
}: PropsWithChildren<Props>) => {
  useLayoutEffect(() => {
    circleSvgData.forEach((svgData) => {
      const circleSvg = d3.select("#circle-svg");
      circleSvg
        .append("g")
        .append("path")
        .attr(
          "transform",
          `translate(${circleSvgSize / 2}, ${circleSvgSize / 2})`,
        )
        .attr(
          "d",
          d3.arc()({
            innerRadius: svgData.radius,
            outerRadius: svgData.radius + circleWidth,
            startAngle: -Math.PI,
            endAngle: Math.PI,
          }),
        )
        .attr("fill", "#4E1AEF")
        .attr("opacity", svgData.opacity);
    });
  }, [circleSvgData, circleSvgSize, circleWidth]);

  return (
    <Center width={"100%"} position={"relative"} style={style}>
      <svg width={circleSvgSize} height={circleSvgSize} id="circle-svg"></svg>
      <Center
        width={circleSvgSize}
        height={circleSvgSize}
        position={"absolute"}
      >
        {children}
      </Center>
    </Center>
  );
};

export default DrawnCirclesBackground;
