import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/queryClient';
import App from './App';
import { AuthProvider } from './context/auth';

import './global.css';

import * as Sentry from "@sentry/react";
import DocumentUiProvider from './providers/DocumentUiProvider';
import components from './providers/DocumentUiProvider/components';
import isDebug from './utils/isDebug';
import TelemetryProvider from './providers/TelemetryProvider';


Sentry.init({
  enabled: !isDebug,
  dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
  release: `${import.meta.env.PACKAGE_VERSION}-${import.meta.env.VITE_GIT_COMMIT}`,
  environment: `${import.meta.env.VITE_SENTRY_ENV}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <TelemetryProvider>
          <DocumentUiProvider components={components}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </DocumentUiProvider>
        </TelemetryProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}
