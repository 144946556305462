export const APP_NAME = 'MyOmnia';
export const APP_DESCRIPTION = 'Empowering Your Wholeness';
export const HARDCODED_APP_VERSION = 'v1.0.0'; // TODO: hardcoded until we decide on app versioning

export const CALENDLY_ROOT_ELEMENT_ID = '__calendly';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NONE_OF_THE_ABOVE_LABEL = 'None of the above';
export const MODAL_QUESTION_FORM_KEY = 'modal_form_key';
