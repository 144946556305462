import {
  useToast as useChakraToast,
  type UseToastOptions,
} from "@chakra-ui/react";

import toastMessages from "@/messages/toast";
import { AXIOS_TIMEOUT_ERROR_CODE } from "@/constants/errors";

export const getDefaultToastProps = () => ({
  title: toastMessages.genericErrorHeading,
  description: toastMessages.genericErrorDescription,
  isClosable: true,
  variant: "left-accent",
  timeout: 3000,
  id: "e6ffafc3",
});

/**
 * Extended version of the Chakra toast. It validates errors and returns a default toast
 * @returns {func} toast - It can be used as the native Chakra toast or with the extended features
 */
const useToast = () => {
  const chakraToast = useChakraToast();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (toastConfig: UseToastOptions, error?: any) => {
    if (error?.code === AXIOS_TIMEOUT_ERROR_CODE) {
      return chakraToast({
        ...getDefaultToastProps(),
        title: toastMessages.requestTimeoutHeading,
        description: toastMessages.requestTimeoutDescription,
        status: "error",
        duration: 3000,
        position: 'bottom'
      });
    }

    return chakraToast({
      status: "success",
      position: toastConfig.status === "error" ? "bottom" : "top-right",
      ...getDefaultToastProps(),
      ...toastConfig,
    });
  };
};

export default useToast;
