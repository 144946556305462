import { updateUserNSubmitEulaPut } from "@/services/api/requests/user";
import { TUserContactInfo } from "@/types/User.types";
import { useMutation } from "@tanstack/react-query";
import useToast from "../useToast";
import { TCustomAxiosError } from "@/services/api";
import { TEulaAcceptance } from "@/modules/onboarding/organization/types";
import { useAppStore } from "@/store/useAppStore";
import { acceptedDocumentsGet, acceptEulaDocumentPost } from "@/services/api/requests/legal";

const useUserInfoAndEulaAccept = (onSuccessCallback?: () => void) => {
  const toast = useToast()
  const { setUser, legalAcceptanceStatuses, setLegalAcceptanceStatuses } = useAppStore()

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ userInfo, eulaAcceptanceInfo }: { userInfo: TUserContactInfo, eulaAcceptanceInfo?: TEulaAcceptance }) => {
      const response = await updateUserNSubmitEulaPut(userInfo);

      if(eulaAcceptanceInfo){
        const documentIds = legalAcceptanceStatuses?.map((document) => document._id) || []
        const promises = documentIds.map((documentId) => acceptEulaDocumentPost(documentId))
        await Promise.all(promises)

        const updatedLegalStatues = await acceptedDocumentsGet();
        setLegalAcceptanceStatuses(updatedLegalStatues)
      }

      return response
    },
    onSuccess: (user) => {
      setUser(user)

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message,
        status: 'error'
      })
    }
  })

  return { isPending, isError, mutate };
}

export default useUserInfoAndEulaAccept;