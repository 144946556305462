import { hexToRGBA } from '@/theme/utils';
import { VStack } from '@chakra-ui/react';
import React, { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
  color1?: string;
  color1Alpha?: number;
  color12?: string;
  color12Alpha?: number;
  color2?: string;
  color2Alpha?: number;
  width?: number | string;
  height?: number | string;
  padding?: number | string;
};

const ColorOverlay = ({
  style,
  color1 = '#FFFFFF',
  color1Alpha = 1,
  color12,
  color12Alpha,
  color2 = color1,
  color2Alpha = 0,
  height = '100%',
  width = '100%',
  padding,
}: Props) => {
  const color1rgba = hexToRGBA(color1, color1Alpha);
  const color12rgba = hexToRGBA(color12 ?? color1, color12Alpha ?? color1Alpha);
  const color22rgba = hexToRGBA(color12 ?? color2, 0.4);
  const color2rgba = hexToRGBA(color2, color2Alpha);

  return (
    <VStack
      id="the-color-overlay"
      style={style}
      position="absolute"
      transition={'height 0.1s'}
      bgGradient={
        color12 !== undefined && color12Alpha !== undefined
          ? `linear(${color1rgba} 30%, ${color12rgba} 50%, ${color22rgba} 75%, ${color2rgba})`
          : `linear(${color1rgba}, ${color2rgba})`
      }
      width={width}
      height={height}
      padding={padding}
      top={0}
    />
  );
};

export default ColorOverlay;
