import { Center, Text } from '@chakra-ui/react'
import React from 'react'
import BlueTopPage from '../BlueTopPage'

const NotFoundPage = () => {
  return (
    <BlueTopPage title='Oops!' description='404 page not found.'>
      <Center maxWidth={{ md: '295px' }}>
        <Text textAlign={'center'}>Check that you type the address correctly, go back to your previous page or try using our site search to find something specific.</Text>
      </Center>
    </BlueTopPage>
  )
}

export default NotFoundPage