
import { MAIN_CONTENT_MARGIN_TOP } from "@/constants/dimensions";
import { Center, Spinner, SpinnerProps } from "@chakra-ui/react";

const Loader = ({
  marginTop = MAIN_CONTENT_MARGIN_TOP,
  size = "xl",
  width = "100%",
}: {
  marginTop?: string | number;
  size?: SpinnerProps["size"];
  width?: string;
}) => {
  return (
    <Center width={width} marginTop={marginTop}>
      <Spinner size={size} color="primary.500" borderWidth={"3px"} />
    </Center>
  );
};

export default Loader;
