import AppPageTemplate from '@/components/templates/AppPageTemplate';
import onboardingMessages from '@/messages/onboarding';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  subTitle: string;
  description: string;
  submitLabel?: string;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
  hideSeparator?: boolean;
  onSubmit?: () => void;
  onGoBack?: () => void;
};

const OrgOnboardingPage = ({
  title,
  subTitle,
  submitLabel = onboardingMessages.continue,
  description,
  children,
  onGoBack,
  onSubmit,
  isSubmitDisabled = false,
  hideSeparator = false,
  isSubmitLoading = false,
}: PropsWithChildren<Props>) => {
  return (
    <AppPageTemplate>
      <VStack>
        <Text
          marginTop={'20px'}
          variant={'loraTitle'}
          color={'text.darkBlue'}
          fontSize={'26px'}
          lineHeight={'34px'}
          fontWeight={'400'}
        >
          {title}
        </Text>
        <Text marginTop={'54px'} fontSize={'20px'} color={'text.darkBlue'} lineHeight={'24px'} fontWeight={'600'}>
          {subTitle}
        </Text>
        <Text marginTop={'20px'} color={'text.mediumGray'} lineHeight={'20px'} fontWeight={'500'}>
          {description}
        </Text>
      </VStack>
      {children}
      {!hideSeparator && <HStack width={'100%'} bg="background.separatorBlue" height={'2px'} marginY={'54px'} />}
      <Button
        variant={'formSubmit'}
        disabled={isSubmitDisabled}
        isDisabled={isSubmitDisabled}
        _hover={{ backgroundColor: isSubmitDisabled ? 'secondary.400' : 'secondary.600' }}
        onClick={onSubmit}
        isLoading={isSubmitLoading}
      >
        {submitLabel}
      </Button>
      <Text
        onClick={onGoBack}
        cursor={'pointer'}
        marginY={'40px'}
        fontWeight={700}
        fontSize={'16px'}
        color={'text.mediumGray'}
        lineHeight={'18px'}
      >
        {onboardingMessages.goBack}
      </Text>
    </AppPageTemplate>
  );
};

export default OrgOnboardingPage;
