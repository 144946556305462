import { useLayoutEffect, useState } from 'react';

import { Image, Slider as ChakraSlider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import iconsPng from '@/assets/img/png/icons';
import invertedSliderColorPalette from './colors/inverted';
import sliderColorPalette from './colors/standard';
import { SLIDER_DEFAULT_VALUE } from '@/constants/defaultValues';

type Props = {
  setValue: (_: number) => void;
  value?: number;
  defaultValue?: number;
  sliderValues?: Array<number>;
  step?: number;
  min?: number;
  max?: number;
  inverted?: boolean;
};

const Slider = ({
  setValue,
  defaultValue = SLIDER_DEFAULT_VALUE,
  value,
  step = 1,
  min = 1,
  max = 10,
  // sliderValues = [0, 25, 50, 75, 100],
  inverted = false,
}: Props) => {
  const [localValue, setLocalValue] = useState(value ? value : defaultValue);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const updateLocalValue = (val: number) => {
    setLocalValue(val);
    setIsValueChanged(true);
  };

  useLayoutEffect(() => {
    if (!isValueChanged && value !== undefined) {
      updateLocalValue(value);
      setIsValueChanged(true);
    }
  }, [isValueChanged, value]);

  return (
    <ChakraSlider
      step={step}
      min={min}
      max={max}
      transformOrigin={'center'}
      focusThumbOnChange={false}
      onChange={updateLocalValue}
      onChangeEnd={(val) => {
        setLocalValue(val);
        setValue(val);
      }}
      value={localValue}
      height={'20px'} // to make it easier to click-choose a value
      width={'97%'} // squish the Slider a bit on the sides, to help center the dot at the values "Less" and "More"
      marginTop={'8px'}
    >
      <SliderTrack
        height={'3px'}
        bgGradient={inverted ? 'linear(90deg, #00B547,#A2E2C4,#EC4D84)' : 'linear(90deg, #EC4D84,#A2E2C4,#00B547)'}
      >
        <SliderFilledTrack backgroundColor={'transparent'} />
      </SliderTrack>

      <SliderThumb
        backgroundColor={inverted ? invertedSliderColorPalette[localValue] : sliderColorPalette[localValue]}
        borderColor={isValueChanged ? 'white' : '#CCDEEF'}
        boxSize="30px"
        borderWidth={isValueChanged ? '3px' : '1px'}
      >
        {!isValueChanged && <Image width={30} height={30} alt="slider-circle" src={iconsPng.sliderCircleUntouched} />}
      </SliderThumb>
    </ChakraSlider>
  );
};

export default Slider;
