import React from 'react'
import givingToEmployeesContent from './contents/toEmployees'
import howMyOmniaCanHelpContent from './contents/myOmniaHelp'
import { Box, Center, Image, Text, VStack } from '@chakra-ui/react'
import { FORM_FIELD_WIDTH, MAX_CONTAINER_WIDTH } from '@/constants/dimensions'

export type TInfoIllustrationVariant = 'giving_to_your_employees' | 'how_can_my_omnia_help' 
export interface TIlustrationContent {
  title: string,
  infoItems: {
    iconPath: string,
    title: string,
    description: string,
  }[]
}


type Props = {
  variant: TInfoIllustrationVariant
}

const contentMap: Record<TInfoIllustrationVariant, TIlustrationContent> = {
  giving_to_your_employees: givingToEmployeesContent,
  how_can_my_omnia_help: howMyOmniaCanHelpContent
}

const MyOmniaInfoIllustrations = ({ variant = 'how_can_my_omnia_help'}: Props) => {
  const {title, infoItems} = contentMap[variant]
  
  return (
    <Center maxWidth={MAX_CONTAINER_WIDTH} flexDirection={'column'}>
      <Text variant={"loraTitle"} color={"text.veryDarkBlue"} fontSize={'30px'} lineHeight={'38px'}>{title}</Text>
      <Center flexWrap={"wrap"} gap={"10px"} marginTop={"80px"} alignItems={'flex-start'}>
        {infoItems.map((item) => <VStack key={item.title} maxW={FORM_FIELD_WIDTH} alignItems={"start"}>
          <Image
            alt={item.title}
            height={50}
            width={50}
            src={item.iconPath}
          />
          <Text
            variant={"loraTitle"}
            fontSize={"26px"}
            color={"text.veryDarkBlue"}
            fontWeight={'600'}
            lineHeight={'32px'}
            marginTop={"12px"}
            marginBottom={"12px"}
            textAlign={'left'}
          >
            {item.title}
          </Text>
          <Box display={"inline"}>
            <Text
              fontWeight={'600'}
              fontSize={"16px"}
              color={"text.mediumBlue"}
              opacity={0.8}
              lineHeight={'26px'}
              display={"inline"}
            >
              {item.description}
            </Text>
          </Box>
        </VStack>)}
      </Center>
    </Center>
    
  )
}

export default MyOmniaInfoIllustrations