 
import isDebug from "@/utils/isDebug"
import { Text } from "@chakra-ui/react"
import { createElement } from "react"
import { NodeType } from "./types"
import { isArray, isUndefined } from "@/utils"

const renderJson = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: NodeType | any,
  components: Record<string, string>
): React.ReactNode => {
  const { type, children, id, props } = json;

  if (!type) {
    if (isDebug) {
      return <Text color="extras.red">Element type is missing in JSON configuration</Text>
    }
    return null;
  }

  const component = components[type];
  if (!component) {
    if (isDebug) {
      return <Text color="extras.red">Unsupported component type: {type}</Text>
    }
    return null;
  }

  if (
    !isUndefined(children) && !isArray(children) && type !== "Text" && type !== "Markdown"
  ) {
    return (
      <Text>{`JSON ERROR: Children of component type: ${type} must be an array.`}</Text>
    );
  }

  const _id = id || Math.random().toString(36)

  return createElement(component, {
    ...props,
    key: _id,
    id: _id
  },
    isArray(children) && children.length > 0 ?
      children.map((child: NodeType) => renderJson(child, components)) as React.ReactNode[]
      : children
  );
}

export default renderJson;