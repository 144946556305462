import getUserAgentInfo from '@/utils/getUserAgentInfo';
import isDebugMode from '@/utils/isDebug';
import mixpanel, { Dict } from 'mixpanel-browser';
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

export type TTelemetryContext = {
  trackEvent: (eventName: string, data?: Dict) => void;
  handleRecordingStart: () => void;
  handleRecordingStop: () => void;
  handleSignIn: (userId: string) => void;
  handleSignOut: (userId: string) => void;
};

export const TelemetryContext = createContext<TTelemetryContext | null>(null);

const initializeTelemetry = (config: { token: string; isDebug?: boolean }) => {
  mixpanel.init(config.token, {
    debug: config.isDebug,
    track_pageview: true,
    persistence: 'localStorage',
  });
};

const TelemetryProvider = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    initializeTelemetry({
      token: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
      isDebug: isDebugMode,
    });
  }, []);

  const trackEvent = useCallback((eventName: string, data?: Dict) => {
    mixpanel.track(eventName, data);
  }, []);

  const handleSignIn = useCallback((userId: string) => {
    mixpanel.identify(userId);

    const appVersion = import.meta.env.PACKAGE_VERSION;
    const deviceType = getUserAgentInfo(window.navigator.userAgent).operating_system;

    mixpanel.people.set({
      id: userId,
      app_version: appVersion,
      device_type: deviceType,
    });
  }, []);
  const handleSignOut = useCallback((userId: string) => {
    mixpanel.reset();
    mixpanel.people.remove({ id: userId });
  }, []);
  const handleRecordingStart = useCallback(() => {
    mixpanel.start_session_recording();
  }, []);
  const handleRecordingStop = useCallback(() => {
    mixpanel.stop_session_recording();
  }, []);

  const memoizedEvents = useMemo(
    () => ({
      trackEvent,
      handleSignIn,
      handleSignOut,
      handleRecordingStart,
      handleRecordingStop,
    }),
    [trackEvent, handleSignIn, handleSignOut, handleRecordingStart, handleRecordingStop],
  );

  return <TelemetryContext.Provider value={memoizedEvents}>{children}</TelemetryContext.Provider>;
};

export default TelemetryProvider;
