import type { CSSProperties } from "react";

const SvgIcon = ({
  iconPath,
  color,
  size = "19",
  style,
}: {
  iconPath: string;
  color: string;
  size?: number | string;
  style?: CSSProperties;
}) => {
  return (
    <div style={style}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={iconPath} />
      </svg>
    </div>
  );
};

export default SvgIcon;
