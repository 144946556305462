import { useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import { reinviteUsersToOrganizationPost } from '@/services/api/requests/organization';
import { TCustomAxiosError } from '@/services/api';
import { useAppStore } from '@/store/useAppStore';

const useReinviteMemberToOrganization = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const { trackEvent } = useTelemetry();
  const { organization } = useAppStore();

  const { isPending, mutate } = useMutation({
    mutationFn: async (userId: string) => {
      const organizationId = organization?._id;

      if (!organizationId) throw Error('No Organization ID');

      const response = await reinviteUsersToOrganizationPost(organizationId, userId);

      return response;
    },
    onSuccess: () => {
      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member reinvitation successful!',
      });

      trackEvent(mixpanelEvents.REINVITE_ORG_MEMBER);

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

export default useReinviteMemberToOrganization;
