import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { useAppStore } from '@/store/useAppStore';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { createFileRoute, redirect } from '@tanstack/react-router'

const AssessmentPage = () => {
  return <AppPageTemplate>
    <div>Hello /_authenticated/assessment/$assessmentId!</div>
  </AppPageTemplate>
}

export const Route = createFileRoute(
  '/_authenticated/assessment/$assessmentId',
)({
  component: AssessmentPage,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const { onboardingStatuses, isEulaAccepted } = useAppStore.getState()

    const isOnboardingCompleted = isEverythingSetup(onboardingStatuses, isOrgAdmin)

    if (!isOnboardingCompleted) {
      if (isOrgAdmin) {
        throw redirect({
          to: '/onboarding/organization',
        });
      }

      throw redirect({
        to: '/onboarding/user',
      });
    }

    if (!isEulaAccepted) {
      throw redirect({
        to: '/eula/update',
      });
    }
  }
})
