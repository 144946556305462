import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/login/')({
  component: () => <div></div>,
  beforeLoad: async () => {
    throw redirect({
      to: '/sign-in',
    });
  },
});
