import { type UseMutateFunction, useMutation } from "@tanstack/react-query";

import { forgotPasswordPost } from "@/services/api/requests/user";
import useToast from "../useToast";
import { TCustomAxiosError } from "@/services/api";

const useForgotPassword = (
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<string, TCustomAxiosError, string, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (email: string) => {
      await forgotPasswordPost(email);

      // what we return here will be available in `onSuccess` as first param
      return email;
    },
    onSuccess: (email) => {
      toast({
        status: "success",
        title: "Success",
        description: `Reset Password successful. Please check your email ${email} for instructions`,
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: "error",
          title: "Reset Password Error",
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export { useForgotPassword };
