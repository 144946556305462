import { Text, VStack } from '@chakra-ui/react';
import DoughnutChart, { TChartDataItem } from '@/components/molecules/DoughnutChart';

const UserWellnessGoal = ({ data }: { data: TChartDataItem[] }) => {
  return (
    <VStack>
      <Text variant={'loraTitle'} fontSize={22} marginTop={'60px'} marginBottom={'20px'}>
        Want to set a goal to reach 70 within 3 months?
      </Text>
      <Text fontSize={16} fontWeight={800} color={'primary.500'}>
        FOCUS ON THESE AREAS:
      </Text>
      <DoughnutChart data={data} />
    </VStack>
  );
};

export default UserWellnessGoal;
