import React, { PropsWithChildren, useMemo } from 'react'

type TDocumentUiProviderProps = {
  components: Record<string, string>
}

export const DocumentUiProviderContext = React.createContext<TDocumentUiProviderProps>({
  components: {}
})
DocumentUiProviderContext.displayName = 'DocumentUiProviderContext'


const DocumentUiProvider = ({children, components}: PropsWithChildren<TDocumentUiProviderProps>) => {
  const memoizedValue = useMemo(() => ({components}), [components])

  return (
    <DocumentUiProviderContext.Provider value={memoizedValue}>
      {children}
    </DocumentUiProviderContext.Provider>
  )
}

export default DocumentUiProvider