import GoBackLeftArrow from '@/components/atoms/GoBackLeftArrow';
import DemographicsForm from '@/components/organisms/DemographicsForm';
import { CENTERED_FORM_WIDTH } from '@/constants/dimensions';
import useToast from '@/hooks/useToast';
import { updateUserDemoGraphicsPut } from '@/services/api/requests/user';
import { useAppStore } from '@/store/useAppStore';
import { TUserDemographicsInfo, TUserDemographicsInfoWithoutPicture } from '@/types/User.types';
import { Center, Text, VStack } from '@chakra-ui/react';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useState } from 'react';

const ProfileDemographicsEdit = () => {
  const router = useRouter();
  const toast = useToast();

  const { user, setUser } = useAppStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: TUserDemographicsInfo) => {
    setIsSubmitting(true);

    const userDataNoPic: TUserDemographicsInfoWithoutPicture = { ...data };
    delete userDataNoPic.picture;

    const newUserData = await updateUserDemoGraphicsPut(userDataNoPic);
    setUser(newUserData);

    toast({
      status: 'success',
      title: 'Success',
      description: 'User update successful!',
      position: 'top-right',
    });

    router.history.back();
  };

  return (
    <VStack marginBottom={100}>
      <GoBackLeftArrow />

      <Center height={'50px'}>
        <Text variant={'lora'} fontSize={'24px'} fontStyle={'oblique'}>
          Edit Your Demographics
        </Text>
      </Center>

      <VStack maxWidth={{ md: CENTERED_FORM_WIDTH }} marginTop={'80px'}>
        <DemographicsForm
          defaultFormValues={user?.user_metadata as TUserDemographicsInfo}
          onSubmit={onSubmit}
          isLoading={isSubmitting}
        />
      </VStack>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_user/_profile/user/profile/demographics')({
  component: ProfileDemographicsEdit,
});
