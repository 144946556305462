import { TUserDemographicsInfo } from '@/types/User.types';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

type TOnboardingState = {
  step: number;
  isCompleted: boolean;
  // demographics
  demographics: TUserDemographicsInfo | null;
  setDemographics: (demographics: TUserDemographicsInfo) => void;
  setStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
};

const initialState = {
  step: 0,
  isCompleted: false,
  demographics: null
};

/**
 * NOTE: Example of a store that requires persistence and subscription.
 * State will persis for user even after page refresh.
 * Use this as a reference when creating a new store.
 */
export const useUserOnboardingStore = create<TOnboardingState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setDemographics: (demographics: TUserDemographicsInfo) => {
          set((state) => ({
            ...state,
            demographics
          }))
        },
        setStep: (step: number) => {
          set((state) => {
            if (step >= 0) {
              return { ...state, step };
            }
            return state;
          });
        },
        nextStep: () => {
          set((state) => {
            if (!state.isCompleted) {
              return { ...state, step: state.step + 1 };
            }
            return state;
          });
        },
        prevStep: () =>
          set((state) => {
            if (state.step === 0) {
              return state;
            }
            return { ...state, step: state.step - 1 };
          }),
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
          }));
        },
      }),
      { name: 'userOnboardingStore' },
    ),
  ),
);
