import { background, extra, primary, secondary, text } from "./palettes";

const colors = {
  primary: {
    ...primary
  },
  secondary: {
    ...secondary
  },
  background: {
    ...background
  },
  text: {
    ...text
  },
  extra: {
    ...extra
  },
}

export default colors;