export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const base64ToFile = (base64Content: string, fileName: string): File => {
  // To retrieve only the Base64 encoded string, first remove `data:*/*;base64,` from the result. https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
  const trimmedContent = base64Content.substring(
    base64Content.indexOf(",") + 1,
  );
  const buff = Buffer.from(trimmedContent, "base64");
  return new File([buff], fileName, {
    type: fileName.endsWith("png") ? "image/png" : "image/jpeg",
  });
};
