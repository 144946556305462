import Markdown from '@/components/atoms/Markdown/Markdown';
import { Button, HStack, VStack, Text, Image } from '@chakra-ui/react';
import DocumentSliderForm from './DocumentSliderForm';
import DocumentSlide from './DocumentSlide';
import ControlledSliderExtended from './SliderExtended/ControlledSliderExtended';
import MultiSelectInput from './MultiSelectInput';
import SingleSelectInput from './SingleSelectInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: Record<string, any> = {
  Markdown,
  Slider: ControlledSliderExtended,
  ASSESSMENT: DocumentSliderForm,
  SCREENER: DocumentSliderForm,
  QuestionContainer: DocumentSlide,
  MultiSelectInput,
  SingleSelectInput,
  Button,
  VStack,
  HStack,
  Text,
  Image,
};

export default components;
