import { Button, HStack, Progress, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useEmblaCarousel from 'embla-carousel-react';
import useAssessmentResult from '@/hooks/assessments/useAssessmentResult';
import { isArray } from '@/utils';
import { useScreenerStore } from '@/store/useScreenerStore';
// import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
// import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import ModalContainer from '../../../components/molecules/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { format } from 'date-fns';

type Props = {
  title: string;
  subTitle: string;
};

const DocumentSliderForm = ({ title, subTitle, children }: PropsWithChildren<Props>) => {
  const {
    setCurrentSlide: setCurrentSlideToStore,
    currentSlide: currentSlideFromStore,
    currentFormState: currentFormStateFromStore,
    setCurrentFormState: setCurrentFormStateToStore,
    reset: resetScreenerStore,
    timeStarted,
    setTimeStarted,
  } = useScreenerStore();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { isPending, mutate } = useAssessmentResult(resetScreenerStore);

  const { getValues, formState, setValue, reset: resetCurrentForm } = methods;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    Object.entries(currentFormStateFromStore).forEach((entry) =>
      setValue(entry[0], entry[1], { shouldDirty: true, shouldTouch: true }),
    );

    if (timeStarted) {
      setShowConfirmationModal(true);
    } else {
      setTimeStarted(new Date());
    }
  }, []);

  const [currentSlide, setCurrentSlide] = useState(currentSlideFromStore);
  const [currentSlideQuestionNames, setCurrentSlideQuestionNames] = useState<string[]>([]);

  useEffect(() => {
    setCurrentSlideToStore(currentSlide);
  }, [currentSlide]);

  const [totalSlides, setTotalSlides] = useState(0);
  const progress = totalSlides == 0 ? 0 : (currentSlide / totalSlides) * 100; // ternary expression removes initial 100% progress flicker

  const isLastSlide = currentSlide === totalSlides - 1;

  const { dirtyFields } = formState;

  useEffect(() => {
    if (isArray(children)) {
      const currentSlideElements = children[currentSlide].props.children;
      if (isArray(currentSlideElements)) {
        // all the question elements have `healthDomain` in their props
        const currentSlideQuestions = currentSlideElements.filter(
          (questionElement) => questionElement?.props?.healthDomain !== undefined,
        );
        const slideQuestionNames = currentSlideQuestions.map((question) => question.props.name);
        if (currentSlideQuestionNames?.[0] !== slideQuestionNames?.[0]) {
          setCurrentSlideQuestionNames(slideQuestionNames);
        }
      }
    }
  }, [children, currentSlide]);

  const continueDisabled = useMemo(() => {
    const numberOfQuestions = currentSlideQuestionNames.length;
    const numberOfAnsweredQuestions = currentSlideQuestionNames.filter(
      (questionName) => dirtyFields[questionName],
    ).length;
    return numberOfQuestions !== numberOfAnsweredQuestions;
  }, [currentSlideQuestionNames, Object.keys(dirtyFields).length]);

  const goBackVisible = useMemo(() => currentSlide > 0, [currentSlide]);
  // const rightArrowVisible = useMemo(() => !continueDisabled && !isLastSlide, [continueDisabled, isLastSlide]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: 0,
    watchDrag: () => {}, // disables dragging
  });

  const goToNextSlide = () => {
    setCurrentSlide((prev) => prev + 1);
    emblaApi?.scrollNext();

    setCurrentFormStateToStore(getValues());
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => prev - 1);
    emblaApi?.scrollPrev();
  };

  const onContinue = () => {
    const formData = getValues();

    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      goToNextSlide();

      window.scrollTo({ top: 100, behavior: 'smooth' });

      return;
    }

    mutate({ formValues: formData });
  };

  useEffect(() => {
    if (emblaApi) {
      emblaApi?.scrollTo(currentSlideFromStore);

      if (totalSlides === 0) {
        setTotalSlides(emblaApi.slideNodes().length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  const restartScreener = useCallback(() => {
    resetCurrentForm();
    setCurrentSlide(0);

    setTimeStarted(new Date());

    setShowConfirmationModal(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <VStack>
          <VStack maxWidth={{ md: '820px' }} width={'100%'}>
            <Progress
              colorScheme="primary"
              height={'6px'}
              value={progress}
              width={'100%'}
              borderRadius={'20px'}
              backgroundColor={'rgba(78, 26, 239, 0.075)'}
            />
            <Text
              variant={'lora'}
              textAlign={'center'}
              fontSize={{ md: '26px' }}
              fontStyle={'italic'}
              lineHeight={'32px'}
              color={'text.darkBlue'}
              marginTop={'34px'}
            >
              {title}
            </Text>
            <Text textAlign={'center'} fontSize={'14px'} lineHeight={'16px'} color="text.mediumGray" marginTop={'24px'}>
              {subTitle}
            </Text>
            {(title || subTitle) && (
              <HStack
                height={'1px'}
                maxWidth={'414px'}
                width={'100%'}
                bg="background.blueGray"
                marginY={'30px'}
                borderRadius={'full'}
                opacity={0.3}
              />
            )}
          </VStack>
        </VStack>
        {/* using timeStarted as key allows us to trigger re-render of children (slides) upon starting a new screener session */}
        <section className="embla" key={timeStarted?.toString()}>
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">{children}</div>
          </div>
        </section>
      </form>

      {/* Continue/Finish button */}
      <VStack marginTop={'20px'} marginBottom={'40px'} width={'100%'}>
        <Button
          variant={isLastSlide ? 'formSubmit' : 'continue'}
          isDisabled={continueDisabled}
          _hover={{
            backgroundColor: continueDisabled
              ? isLastSlide
                ? 'secondary.500'
                : 'primary.500'
              : isLastSlide
                ? 'secondary.600'
                : 'primary.600',
          }}
          onClick={onContinue}
          isLoading={isPending}
        >
          {isLastSlide ? 'Finish Screener' : 'Continue'}
        </Button>

        <CustomButton
          isTransparent
          labelHoverColor="primary.black"
          label="Go Back"
          borderHoverColor="transparent"
          onClick={goToPreviousSlide}
          style={{ visibility: goBackVisible ? 'visible' : 'hidden' }}
        />
      </VStack>

      {/* Previous/Next slide arrows - TODO: keeping them as they will be needed later */}
      {/* <HStack
        width={MAX_CONTAINER_WIDTH}
        position={'absolute'}
        justifyContent={'space-between'}
        bottom={'50%'}
        backgroundColor={'transparent'}
      >
        {leftArrowVisible ? (
          <Button
            onClick={goToPreviousSlide}
            width={'50px'}
            height={'50px'}
            borderRadius={'full'}
            boxShadow={'0px 4px 15px 0px rgba(0, 0, 0, 0.5)'}
            _hover={{ boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)' }}
          >
            <ArrowBackIcon color={'primary.600'} boxSize={6} />
          </Button>
        ) : (
          <VStack />
        )}

        {rightArrowVisible ? (
          <Button
            onClick={goToNextSlide}
            width={'50px'}
            height={'50px'}
            borderRadius={'full'}
            boxShadow={'0px 4px 15px 0px rgba(0, 0, 0, 0.5)'}
            _hover={{ boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)' }}
          >
            <ArrowForwardIcon color={'primary.600'} boxSize={6} />
          </Button>
        ) : (
          <VStack />
        )}
      </HStack> */}

      {/* Continue Confirmation Modal */}
      <ModalContainer
        isOpen={showConfirmationModal}
        closeOnOverlayClick={false}
        onClose={() => setShowConfirmationModal(false)}
        isBlur={false}
        width={'520px'}
        height={'270px'}
        backgroundColor={'background.lightGray'}
        closeButtonColor={'black'}
        footerContent={
          <HStack justifyContent={'center'} width={'100%'} height={'100%'} spacing={'10px'} marginBottom={'10px'}>
            <CustomButton
              isTransparent
              label="Start Over"
              labelStyle={{ fontSize: '16px' }}
              labelColor="text.mediumBlue"
              labelHoverColor={colors.extra.black}
              borderHoverColor="transparent"
              onClick={restartScreener}
              height={'60px'}
              width={'120px'}
            />
            <CustomButton
              label="Continue"
              labelStyle={{ fontSize: '16px' }}
              labelHoverColor={colors.extra.white}
              backgroundColor="secondary.500"
              labelColor="extra.white"
              onClick={() => setShowConfirmationModal(false)}
              height={'60px'}
              width={'120px'}
            />
          </HStack>
        }
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
          Continue where you left off?
        </Text>
        <VStack marginTop={'10px'} justifyContent={'center'}>
          <Text variant={'urbanistMedium'} color={'text.mediumBlue'} fontSize={'16px'}>
            You started this screener on {timeStarted ? format(timeStarted, `MM/dd/yyyy hh:mm a`) : ''}.
          </Text>
          <Text variant={'urbanistMedium'} color={'text.mediumBlue'} fontSize={'16px'} marginTop={'10px'}>
            Would you like to continue or start over?
          </Text>
        </VStack>
      </ModalContainer>
    </FormProvider>
  );
};

export default DocumentSliderForm;
