import React, { useEffect, useState } from 'react';
import { TDocumentComponentBase } from '../types';
import { TAssessmentModal } from '@/types/Assessment.types';
import { Controller, useFormContext } from 'react-hook-form';
import RadioButtonGroup from '@/components/molecules/RadioButtonGroup';
import { MODAL_QUESTION_FORM_KEY } from '@/constants/stringVars';

type TSingleSelectInputOption = {
  label: string;
  value: number;
};

type Props = TDocumentComponentBase & {
  options: TSingleSelectInputOption[];
  modal?: TAssessmentModal;
};

const SingleSelectInput = ({ options, modal, name }: Props) => {
  const { control, setValue } = useFormContext();

  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    //  options.find(selected)?.value === 0  ==>  if the selected value is the value with score = 0
    const selectedOption = options?.find((option) => option.label === selectedLabel);

    let modalUrgencyValue = '';
    // in case of SingleSelectInput, we assign modal urgency whatever it may be as soon as we have a selected option,
    // because only one option (i.e. 'any') can be selected; no need to check whether the modal condition is 'any' or 'all'
    if (modal?.urgency && selectedOption) {
      if (selectedOption.value > 0) {
        modalUrgencyValue = '';
      } else {
        modalUrgencyValue = modal.urgency;
      }

      // TODO: currently sending only Yes/No; we should probably send the whole option; once we do, make sure refresh-remembering works well
      setValue(name, { value: selectedLabel, [MODAL_QUESTION_FORM_KEY]: modalUrgencyValue }, { shouldDirty: true });
    }
  }, [options, selectedLabel]);

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={() => (
        <RadioButtonGroup
          vertical
          items={options.map((option) => option.label)}
          selectedItem={selectedLabel}
          onClick={(option: string) => setSelectedLabel(option)}
          containerStyle={{ margin: '40px 0' }}
          fontSize="lg"
        />
      )}
    />
  );
};

export default SingleSelectInput;
