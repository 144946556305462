import type { CSSProperties } from 'react';

import { Box, HStack, Input, Text, VStack } from '@chakra-ui/react';

const MAX_CHARS_IN_LINE = 35;

export type TRadioButtonFontSize = 'sm' | 'lg';

const RadioButton = ({
  label,
  isSelected,
  hasTextInput,
  textInputValue,
  setTextInputValue,
  onClick,
  buttonStyle,
  noOfLines,
  fontSize = 'sm',
}: {
  label: string;
  isSelected: boolean;
  hasTextInput?: boolean;
  textInputValue?: string;
  setTextInputValue?: (_: string) => void;
  onClick: () => void;
  buttonStyle?: CSSProperties;
  noOfLines?: number;
  fontSize?: TRadioButtonFontSize;
}) => {
  const twoOrMoreLines = label.length >= MAX_CHARS_IN_LINE && noOfLines && noOfLines >= 2;

  return (
    <HStack
      gap={0}
      background={'extra.white'}
      boxShadow={isSelected ? '' : `0px 2px 8px 0px #00417926`}
      borderRadius={8}
      padding={'16px'}
      paddingTop={hasTextInput || twoOrMoreLines ? '8px' : '16px'}
      paddingBottom={hasTextInput || twoOrMoreLines ? '10px' : '16px'}
      width={300}
      onClick={onClick}
      borderWidth={2}
      borderColor={isSelected ? 'primary.500' : 'transparent'}
      _hover={{
        cursor: 'pointer',
        boxShadow: isSelected ? '' : `0px 2px 16px 0px #00417926`,
      }}
      style={buttonStyle}
    >
      <Box
        marginTop={hasTextInput ? '3px' : 0}
        marginRight={'20px'}
        borderWidth={isSelected ? 8 : 0}
        borderRadius={'xl'}
        minWidth={22}
        height={22}
        backgroundColor={isSelected ? 'extra.white' : 'background.darkGray'}
        borderColor={isSelected ? 'primary.500' : 'transparent'}
      ></Box>

      {hasTextInput ? (
        <VStack gap={'1px'}>
          <Text color={'text.mediumGray'} fontWeight={700} fontSize={'10px'} width={'100%'} textAlign={'start'}>
            {label.toUpperCase()}
          </Text>
          <Input
            autoFocus={hasTextInput}
            value={textInputValue}
            onChange={(e) => setTextInputValue?.(e.target.value)}
            borderWidth={0}
            padding={'0px'}
            maxHeight={'20px'}
            focusBorderColor={'transparent'}
            placeholder={'Please specify'}
            color={'text.darkBlue'}
            fontWeight={600}
            _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
          />
        </VStack>
      ) : (
        <Text
          color={isSelected ? 'primary.500' : 'text.darkBlue'}
          fontWeight={600}
          noOfLines={noOfLines}
          maxWidth={'240px'}
          textOverflow={'elipsis'}
          fontSize={fontSize === 'sm' ? '14px' : '18px'}
          lineHeight={'22px'}
        >
          {label}
        </Text>
      )}
    </HStack>
  );
};

export default RadioButton;
