import { Box, Text } from '@chakra-ui/react';

const UserProfileBoxInfoPiece = ({ title, text }: { title: string; text: string }) => {
  return (
    <Box gap={0}>
      <Text color={'background.blueGray'} variant={'urbanistExtraBoldSmall'} fontWeight="900">
        {title}
      </Text>
      <Text color={'text.darkBlue'} variant={'urbanistBold'} marginTop={'4px'} fontWeight={600} noOfLines={1}>
        {text}
      </Text>
    </Box>
  );
};

export default UserProfileBoxInfoPiece;
