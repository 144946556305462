import { Button, HStack } from '@chakra-ui/react'
import React from 'react'
import ProfileDropdownMenu from '../../ProfileDropdownMenu'
import sharedRoutes from '@/constants/routes/shared'
import { primary, text } from '@/theme/colors/palettes'
import svgIcons from '@/assets/svg/icons'
import SvgIcon from '@/components/atoms/SvgIcon'
import authMessages from '@/messages/auth'
import { Link, useRouter } from '@tanstack/react-router'

interface Props {
  isTransparent: boolean
}

const DefaultHeader = ({ isTransparent }: Props) => {
  const { state: { location: { pathname }}} = useRouter()

  const isContactSupport = pathname === sharedRoutes.contactSupport
  const contactSupportColor = isContactSupport ? primary['500'] : isTransparent? 'white' : text.mediumGray

  return (
    <HStack>
      <Button as={Link} variant={'headerLinkButton'} backgroundColor={'transparent'} boxShadow={'none'} color={contactSupportColor} to={sharedRoutes.contactSupport}
        leftIcon={<HStack width={'20px'} alignItems={'center'}>
          <SvgIcon iconPath={svgIcons.email} size={19} color={contactSupportColor} />
        </HStack>}
      >
        {authMessages.contactSupport}
      </Button>
      <ProfileDropdownMenu isHeaderTransparent={isTransparent}/>
    </HStack>
  )
}

export default DefaultHeader