import roles from '@/constants/roles';
import { THealthDomainScores } from '@/types/Health.types';
import { TLegalDocumentAcceptanceStatus } from '@/types/Legal.types';
import { TOrganization } from '@/types/Organization.types';
import { TUser } from '@/types/User.types';
import { isUndefined } from '@/utils';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

export type TOnboardingStatuses = {
  isUserContactInfoCompleted: boolean;
  isUserDemographicsCompleted: boolean;
  isUserOnboardingCompleted: boolean;
  // isUserOnboardingCompleted => isDemographicsCompleted && isUserContactInfoCompleted isEulaAccepted
  isOrganizationOnboardingCompleted: boolean; // While Organization !== null
  isWholenessScreenerCompleted: boolean;
}


export type TAppDataSetState = {
  // is complete organization data set
  isOrganizationSet: boolean;
  // is complete user data set
  isUserDataSet: boolean;
  isUserContactInfoSet: boolean;
  isUserDemographicSet: boolean;
  // is initial screener completed
  isUserScoresSet: boolean;
  // is EULA accepted
  isEulaAccepted: boolean;
}

type TAppStoreState = {
  onboardingStatuses: TOnboardingStatuses;
  isOnboardingCompleted: boolean;
  isEulaAccepted: boolean;
  user: TUser | null;
  organization: TOrganization | null;
  userScores: THealthDomainScores | null;
  legalAcceptanceStatuses: TLegalDocumentAcceptanceStatus[] | null;
  setUser: (user: TUser) => void;
  setOrganization: (organization: TOrganization) => void;
  setUserScores: (scores: THealthDomainScores) => void;
  setLegalAcceptanceStatuses: (statuses: TLegalDocumentAcceptanceStatus[]) => void;
  setAppStoreOnSignIn: (user: TUser,
    scores: THealthDomainScores,
    legalStatuses: TLegalDocumentAcceptanceStatus[],
    organization: TOrganization | null) => void,
  reset: () => void;
};

const initialState = {
  onboardingStatuses: {
    isUserContactInfoCompleted: false,
    isUserDemographicsCompleted: false,
    isUserOnboardingCompleted: false,
    isOrganizationOnboardingCompleted: false,
    isWholenessScreenerCompleted: false,
  },
  isOnboardingCompleted: false,
  isEulaAccepted: false,
  /// 
  user: null,
  organization: null,
  userScores: null,
  legalAcceptanceStatuses: null,
};

/**
 * NOTE: Example of a store that requires persistence and subscription.
 * State will persis for user even after page refresh.
 * Use this as a reference when creating a new store.
 */
export const useAppStore = create<TAppStoreState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setUser: (user) => {
          set((state) => {
            const { onboardingStatuses } = state

            // TODO: EULA - update is user contact info set to check for EULA
            const isUserContactInfoCompleted = !!user?.user_metadata?.phone && user?.user_metadata?.phone !== '';
            const isUserDemographicsCompleted = !!user?.user_metadata?.dateOfBirth;

            const isUserOnboardingCompleted = isUserContactInfoCompleted && isUserDemographicsCompleted

            const newOnboardingStatuses = {
              ...onboardingStatuses,
              isUserContactInfoCompleted,
              isUserDemographicsCompleted,
              isUserOnboardingCompleted
            }

            const isOnboardingCompleted = isEverythingSetup({...onboardingStatuses, isUserContactInfoCompleted, isUserDemographicsCompleted})

            return ({
              ...state,
              onboardingStatuses: newOnboardingStatuses,
              isOnboardingCompleted,
              user,
            })
          });
        },
        setOrganization: (organization) => {
          set((state) => {
            const { onboardingStatuses } = state

            const isOrganizationSet = !!organization?.representativeEmail && !!organization?.logoUrl;

            const isOrganizationOnboardingCompleted = isOrganizationSet;

            const newOnboardingStatuses = {
              ...onboardingStatuses,
              isOrganizationOnboardingCompleted
            }

            const isOnboardingCompleted = isEverythingSetup(newOnboardingStatuses)

            return ({
              ...state,
              onboardingStatuses: newOnboardingStatuses,
              isOnboardingCompleted,
              organization,
            })
          });
        },
        setUserScores: (scores) => {
          set((state) => {
            const { onboardingStatuses } = state

            const isUserScoresSet = Object.keys(scores).length > 0;

            const isWholenessScreenerCompleted = isUserScoresSet;
            const isOnboardingCompleted = isEverythingSetup({...onboardingStatuses, isWholenessScreenerCompleted})

            return ({
              ...state,
              onboardingStatuses: {
                ...onboardingStatuses,
                isWholenessScreenerCompleted
              },
              isOnboardingCompleted,
              userScores: scores,
              
            })
          });
        },
        setLegalAcceptanceStatuses: (statuses: TLegalDocumentAcceptanceStatus[]) => {
          set((state) => {
            const isEulaAccepted = statuses.find(status => isUndefined(status.acceptedAt)) ? false : true;

            return ({
              ...state,
              legalAcceptanceStatuses: statuses,
              isEulaAccepted
            })
          });
        },
        setAppStoreOnSignIn: (
          user: TUser, 
          scores: THealthDomainScores, 
          legalStatuses: TLegalDocumentAcceptanceStatus[], 
          organization: TOrganization | null) => {
          set((state) => {
            const isAdmin = !!user?.roles.includes(roles.orgAdmin);
            const isEulaAccepted = legalStatuses.find(status => isUndefined(status.acceptedAt)) ? false : true;

            let isOrganizationSet = false;
            if(organization){
              isOrganizationSet = !!organization?.representativeEmail && !!organization?.logoUrl;
            }

            const statuses = {
              ...state.onboardingStatuses,
              isUserContactInfoCompleted: !!user?.user_metadata?.phone,
              isUserDemographicsCompleted: !!user?.user_metadata?.dateOfBirth,
              isUserOnboardingCompleted: !!user?.user_metadata?.phone && !!user?.user_metadata?.dateOfBirth,
              isOrganizationOnboardingCompleted: isOrganizationSet,
              isWholenessScreenerCompleted: Object.keys(scores).length > 0,
            }

            const isOnboardingCompleted = isEverythingSetup(statuses, isAdmin);

            return ({
              ...state,
              onboardingStatuses: statuses,
              isOnboardingCompleted,
              user,
              organization,
              userScores: scores,
              legalAcceptanceStatuses: legalStatuses,
              isEulaAccepted
            })
          })
        },
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
          }));
        },
      }),
      { name: 'appStore' },
    ),
  ),
);
