import ConfirmationModal from '@/components/molecules/ConfirmationModal';

interface OrganizationRosterDeleteItemModalProps {
  deleteConfirmationModalVisible: boolean;
  setDeleteConfirmationModalVisible: (modalVisible: boolean) => void;
  deleteItem: (itemId: string) => void;
  itemToDeleteId: string;
  itemToDeleteEmail: string;
}

const OrganizationRosterDeleteMemberModal = ({
  deleteConfirmationModalVisible,
  setDeleteConfirmationModalVisible,
  deleteItem,
  itemToDeleteId,
  itemToDeleteEmail,
}: OrganizationRosterDeleteItemModalProps) => {
  return (
    <ConfirmationModal
      isOpen={deleteConfirmationModalVisible}
      onClose={() => setDeleteConfirmationModalVisible(false)}
      confirmButtonColor="extra.red"
      confirmButtonText="Delete"
      onConfirm={() => {
        deleteItem(itemToDeleteId);
        setDeleteConfirmationModalVisible(false);
      }}
      title="Confirm Deletion"
      description={`Are you sure you want to delete ${itemToDeleteEmail}?`}
    />
  );
};

export default OrganizationRosterDeleteMemberModal;
