import React from 'react'
import OrgOnboardingPage from './OrgOnboardingPage'
import onboardingMessages from '@/messages/onboarding'
import { Box } from '@chakra-ui/react'
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import tableTheme from '@/theme/components/table'
import { TABLE_COLUMNS_ORG_ROSTER_REGISTER } from '@/utils/tableConfigs'
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore'


type Props = {
  onGoBack?: () => void
  onSubmit?: () => void
  isDisabledSubmit?: boolean
  isLoading?: boolean
}

const RosterPreview = ({ onGoBack = () => { }, onSubmit = () => { }, isDisabledSubmit = false, isLoading = false}: Props) => {
  const { rosterParsed: roster } = useOrganizationOnboardingStore()

  const compactTableTheme = useTheme(tableTheme)

  return (
    <OrgOnboardingPage
      title={onboardingMessages.previewYourRosterTitle}
      subTitle={onboardingMessages.previewYourRosterSubtitle}
      description={onboardingMessages.previewYourRosterDescription}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
      submitLabel={onboardingMessages.looksGood}
      isSubmitDisabled={isDisabledSubmit}
      isSubmitLoading={isLoading}
      hideSeparator={true}
    >
      <Box minW={{ base: '925px'}} marginY={'50px'}>
        <CompactTable
          columns={TABLE_COLUMNS_ORG_ROSTER_REGISTER}
          data={{ nodes: roster ?? [] }}
          theme={compactTableTheme}
        />
      </Box>
    </OrgOnboardingPage>
  )
}

export default RosterPreview