import { updateUserDemoGraphicsPut } from "@/services/api/requests/user";
import { TUser, TUserDemographicsInfoWithoutPicture } from "@/types/User.types";
import { useMutation } from "@tanstack/react-query";
import useToast from "../useToast";
import { TCustomAxiosError } from "@/services/api";

const useUpdateDemographics = (onSuccessCallback?: (user: TUser) => void) => {
  const toast = useToast()

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (demographics: TUserDemographicsInfoWithoutPicture) => {
      const user = await updateUserDemoGraphicsPut(demographics)
      return user
    },
    onSuccess: (user) => {
      if (onSuccessCallback) {
        onSuccessCallback(user)
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Failed to update demographics',
        description: error.errors[0].message,
        status: 'error'
      })
    }
  })

  return { isPending, mutate, isError }

}

export default useUpdateDemographics;