import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: () => <div>Hello /!</div>,
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth?.isAuthenticated) {
      throw redirect({
        to: '/sign-in',
      });
    }

    if (auth?.isAuthenticated) {
      throw redirect({
        to: '/user/dashboard',
      });
    }
  },
});
