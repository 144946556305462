import { TUser } from "@/types/User.types";


export const isUserWithData = (user: TUser | null): boolean => {
  if (!user) {
    return false;
  }
  // TODO: Not sure if this is the correct way to check if user data is set, since all users have first name and last name
  return !!user?.user_metadata?.dateOfBirth;
};
