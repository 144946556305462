/**
 * Please add here as a parts all the components that are used in Markdown
 */
const markdownTheme = {
  parts: ["wrapper", "heading", "link", "list", "paragraph"],
  baseStyle: {
    wrapper: {
      textAlign: "start",
      fontSize: "md",
      textColor: "text.mediumBlue",
    },
    link: {
      textDecoration: "none",
      textColor: 'primary.500',
      fontWeight: "bold",
    },
    p: {
      fontSize: "xl",
      fontWeight: "bold",
      mb: 4,
    },
  },
};

export default markdownTheme;
