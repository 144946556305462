import iconsPng from '@/assets/img/png/icons'
import { FORM_FIELD_WIDTH } from '@/constants/dimensions'
import sharedRoutes from '@/constants/routes/shared'
import onboardingMessages from '@/messages/onboarding'
import { Text, Link as ChakraLink, HStack, Tooltip, Image } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'
import React from 'react'

const EulaLinkedText = () => {
  return (
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Text paddingX={'16px'} color={"white"} fontSize={'14px'} lineHeight={'18px'}>{`${onboardingMessages.iAgreeTo} `}
        <ChakraLink as={Link} fontWeight={'800'} to={sharedRoutes.termsOfUse} >
          {onboardingMessages.termsOfUse}
        </ChakraLink>
        {` ${onboardingMessages.and} `}
        <ChakraLink as={Link} fontWeight={'800'} to={sharedRoutes.privacyPolicy} >
          {onboardingMessages.privacyPolicy}
        </ChakraLink>
      </Text>
      <Tooltip 
        label={onboardingMessages.termsOfInfoTooltip}
        placement={"right-start"}
        color={"text.mediumBlue"}
        background={"white"}
        maxWidth={FORM_FIELD_WIDTH}
        borderRadius={"8px"}
        fontWeight={600}
        padding={"10px"}
      >
        <Image src={iconsPng.info} width={25} height={25} alt='info-eula-tooltip'/>
      </Tooltip>
    </HStack>
  )
}

export default EulaLinkedText