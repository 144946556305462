import queryKeys from "@/constants/queryKeys";
import { getMyOrganizationMemberCares } from "@/services/api/requests/member-care";
import { useAppStore } from "@/store/useAppStore";
import { useQuery } from "@tanstack/react-query";

const useOrganizationMemberCares = () => {
  const { user } = useAppStore();

  const { isLoading, data } = useQuery({
    queryKey: [queryKeys.memberCare.myOrganizationMemberCares, user?.email],
    queryFn: () => getMyOrganizationMemberCares(),
  })

  return { isLoading, data }
}

export default useOrganizationMemberCares;