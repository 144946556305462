import onboardingMessages from '@/messages/onboarding';
import UserPasswordPage from '@/modules/user/UserPasswordPage';
import { setInitialUserPassword, verifyAuthToken } from '@/services/api/requests/user';
import { createFileRoute, redirect } from '@tanstack/react-router';

const PasswordSetup = () => {
  const { authToken } = Route.useParams();
  const { email, roles } = Route.useSearch();

  return (
    <UserPasswordPage
      pageTitle={onboardingMessages.emailSuccessfullyVerified}
      pageDescription={onboardingMessages.createYourPassword}
      email={email}
      authToken={authToken}
      userRoles={roles.split(',')}
      verifyTokenCallback={() => verifyAuthToken({ token: authToken, email })}
      setPasswordCallback={(password: string) =>
        setInitialUserPassword({
          email,
          newPassword: password,
          token: authToken,
        })
      }
    />
  );
};

export const Route = createFileRoute('/password-setup/$authToken')({
  component: PasswordSetup,
  validateSearch: (search: Record<string, unknown>): { email: string; roles: string } => ({
    email: search.email as string,
    roles: search.roles as string,
  }),
  beforeLoad: async ({ context: { auth } }) => {
    const isAuthenticated = auth?.isAuthenticated;
    if (isAuthenticated) {
      throw redirect({
        to: '/onboarding/user',
      });
    }
  },
});
