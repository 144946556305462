import { useMemo } from 'react';

import CircleFillOutWithText, { CircleFillOutWithTextProps } from './CircleFillOutWithText';
import { Flex, Text } from '@chakra-ui/react';
import { WELLNESS_STATE_LIMIT_DANGER, WELLNESS_STATE_LIMIT_OK } from '@/constants';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';

const CHUNK_MIN_WIDTH = '190px';
const OUTER_ELLIPSE_BORDER_WIDTH = '3px';

const WellnessStateCircleChunk = ({
  title,
  circleDiameter = 64,
  svgId,
  thickness,
  percentageFilled,
  percentageTextStyle,
  reversed,
}: { title: string; reversed?: boolean } & CircleFillOutWithTextProps) => {
  const calculatedColor = useMemo(() => getColorFromPercentage(percentageFilled), [percentageFilled]);

  const actionText = useMemo(() => {
    if (percentageFilled > WELLNESS_STATE_LIMIT_OK) {
      return (
        <Text fontSize={'10px'} fontWeight={800} color={'background.blueGray'}>
          EXPLORE IDEAS
        </Text>
      );
    }

    if (percentageFilled > WELLNESS_STATE_LIMIT_DANGER) {
      return (
        <Text fontSize={'10px'} fontWeight={800} color={'text.darkBlue'}>
          NEEDS IMPROVEMENT
        </Text>
      );
    }

    return (
      <Text fontSize={'10px'} fontWeight={800} color={'extra.red'}>
        NEEDS ACTION
      </Text>
    );
  }, [percentageFilled]);

  return (
    <Flex
      minWidth={CHUNK_MIN_WIDTH}
      background={'white'}
      borderRadius={'44px'}
      padding={'5px'}
      alignItems={'center'}
      flexDirection={reversed ? 'row-reverse' : 'row'}
      borderColor={percentageFilled < WELLNESS_STATE_LIMIT_OK ? calculatedColor : 'transparent'}
      borderWidth={percentageFilled < WELLNESS_STATE_LIMIT_OK ? OUTER_ELLIPSE_BORDER_WIDTH : 0}
    >
      <CircleFillOutWithText
        circleDiameter={circleDiameter}
        circleColor={calculatedColor}
        svgId={svgId}
        thickness={thickness}
        percentageFilled={Math.floor(percentageFilled)}
        percentageTextStyle={percentageTextStyle}
      />
      <Flex flexDir={'column'} marginX={'25px'}>
        <Text fontWeight={600} fontSize={18}>
          {title}
        </Text>
        <Flex flexDirection={'row'}>{actionText}</Flex>
      </Flex>
    </Flex>
  );
};

export default WellnessStateCircleChunk;
