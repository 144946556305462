import illustrations from "@/assets/img/png/ilustrations";
import { TIlustrationContent } from "..";

const givingToEmployeesContent: TIlustrationContent = {
  title: "What you're giving to your employees:",
  infoItems: [
    {
      title: "Access to Trauma-Vetted Therapy",
      description:
        "Our vetted network of competent therapists are a few clicks away for your team members to schedule confidentially. They can choose telehealth, or meet in person.",
      iconPath: illustrations.nurse
    },
    {
      title: "Wellness Insights and Tracking",
      description:
        "Our wellness screener and reporting help your team understand which areas of their life need the most focused effort. You'll receive the de-identified aggregated data that will help you steer your team in the right direction. See ",
      iconPath: illustrations.headWithCog
    },
    {
      title: "Digital Connection Tools",
      description:
        "Our social media platform will allow your team to organize into groups that you can customize to support each other.",
      iconPath: illustrations.cloudConnected
    },
    {
      title: "Peer Support Consultations",
      description:
        "Having a monthly consultation with a clinical therapist will help with individual needs, as well as departmental initiatives.",
      iconPath: illustrations.headToHead
    }
  ]
}

export default givingToEmployeesContent;