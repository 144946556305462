import { TOnboardingStatuses } from "@/store/useAppStore";
import { isUndefined } from ".";

export const isEverythingSetup = (status: TOnboardingStatuses, isAdmin?: boolean) => {
  const { isOrganizationOnboardingCompleted, isUserOnboardingCompleted, isWholenessScreenerCompleted } = status;

  if (!isUndefined(isAdmin) && isAdmin) {
    return isOrganizationOnboardingCompleted && isUserOnboardingCompleted && isWholenessScreenerCompleted
  }

  return isUserOnboardingCompleted && isWholenessScreenerCompleted;
}