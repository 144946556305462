import { TUserAgentInfo } from "@/types/Legal.types";

const getBrowser = (userAgent: string): string => {
  if (userAgent.includes("Firefox")) return "Firefox";
  if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) return "Chrome";
  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
  if (userAgent.includes("Edg")) return "Edge";
  if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
  return "Unknown";
}

function getOSInfo(userAgent: string): string {
  if (userAgent.includes("Windows NT 10.0")) return "Windows 10";
  if (userAgent.includes("Windows NT 6.1")) return "Windows 7";
  if (userAgent.includes("Mac OS X")) return "Mac OS";
  if (userAgent.includes("X11")) return "Linux";
  if (userAgent.includes("Android")) return "Android";
  if (userAgent.includes("iPhone") || userAgent.includes("iPad")) return "iOS";
  return "Unknown";
}

const getUserAgentInfo = (userAgentString: string): TUserAgentInfo => {
  const operating_system = getOSInfo(userAgentString);
  const browser = getBrowser(userAgentString);

  return { browser, operating_system };
}

export default getUserAgentInfo;