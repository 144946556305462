import { THealthDomain, THealthDomainQuestionType } from '@/types/Health.types';

export const getScoresPerDomainForFormValues = (
  formValues: Record<string, { value: number; type: THealthDomainQuestionType }>,
  formDomains: Record<string, THealthDomain>,
): Record<THealthDomain, number> => {
  const scoresPerDomain: Record<THealthDomain, number> = {
    physical: 0,
    mental: 0,
    social: 0,
    vocational: 0,
    spiritual: 0,
    purpose: 0,
    fun: 0,
  };

  Object.entries(formValues).forEach(([key, value]) => {
    const domain = formDomains[key];
    const valueMultiplier = value.type === 'wellness_level' ? 0.7 : 0.3;
    const calculatedValue = value.value * valueMultiplier;
    scoresPerDomain[domain] += calculatedValue;
  });

  const result: Record<string, number> = {};

  Object.keys(scoresPerDomain).forEach((healthDomain) => {
    // /10 on score commented because initially slider had values 1-100 and step was 10
    const domainScoresSum = scoresPerDomain[healthDomain as THealthDomain] // / 10;
    
    result[healthDomain] = domainScoresSum;
  });

  return result;
};
