export enum ComponentTypes {
  Image = 'Image',
  Markdown = 'Markdown',
  Text = 'Text',
  Slider = 'Slider',
  QuestionContainer = 'QuestionContainer',
  VStack = 'VStack',
  HStack = 'HStack',
  ASSESSMENT = 'ASSESSMENT',
  SCREENER = 'SCREENER',
}

export type NodeType = {
  id?: string;
  type: ComponentTypes;
  props?: Record<string, unknown>;
  children: NodeType[] | string;
  name?: string;
};

export type TDocumentComponentBase = {
  name: string;
};
