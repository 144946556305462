import CalendlyModal from './CalendlyModal';
import { CALENDLY_ROOT_ELEMENT_ID } from '@/constants/stringVars';

import type { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  url: string;
};

const Calendly = ({ isOpen, setIsOpen, url }: Props) => {
  return (
    <>
      <CalendlyModal isOpen={isOpen} setIsOpen={setIsOpen} shareUrl={url} />
      <div id={CALENDLY_ROOT_ELEMENT_ID}></div>
    </>
  );
};

export default Calendly;
