import { TUserContactSupport } from '@/types/User.types';

export const requestCustomerSupportPost = async ({ email, message }: TUserContactSupport) => {
  await fetch('https://hooks.slack.com/triggers/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', {
    method: 'POST',
    body: JSON.stringify({
      email,
      message,
    }),
  });

  return 'ok';
};
