import { TAssessment } from "@/types/Assessment.types";
import { api, TCustomAxiosResponse } from "..";
import { useScreenerStore } from "@/store/useScreenerStore";

const root = 'v1/assessments/assessment';

const endpoints = {
  bySlug: `${root}/slug`,
  createResult: 'v1/assessments/result'
}

export const assessmentBySlugGet = async (slug: string): Promise<TAssessment> => {
  const { data } = await api.get<TCustomAxiosResponse<TAssessment>>(`${endpoints.bySlug}/${slug}`);

  const assessment = data.data;

  const screenerStore = useScreenerStore.getState();

  // TODO: This is a side effect, it should be handled in a different way
  screenerStore.setScreenerId(assessment._id, assessment.slug);

  return assessment;
}

