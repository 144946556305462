import { TRosterItemPerson } from "@/types/Roster.types";
import { TRosterItemWithIdAndRole } from "@/types/Roster.types";

export const TABLE_COLUMNS_ORG_ROSTER_REGISTER = [
  { label: "FIRST NAME", renderCell: (item: TRosterItemPerson) => item["First Name"] },
  {
    label: "LAST NAME",
    renderCell: (item: TRosterItemPerson) => item["Last Name"],
  },
  { label: "PHONE NUMBER", renderCell: (item: TRosterItemPerson) => item.Phone },
  {
    label: "EMAIL ADDRESS",
    renderCell: (item: TRosterItemPerson) => item.Email,
  },
];

export const TABLE_COLUMNS_ORG_ROSTER_VIEW = [
  ...TABLE_COLUMNS_ORG_ROSTER_REGISTER,
  {
    label: 'INVITATION',
    renderCell: (item: TRosterItemWithIdAndRole) => item.Invitation ?? '', // TODO: what to do if no invitation?
  },
];
