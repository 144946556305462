type ColorObject = {
  [key: number]: string;
};

const invertedSliderColorPalette: ColorObject = {
  1: "#00b547",
  2: "#16a355",
  3: "#33be63",
  4: "#4fc77c",
  5: "#68d093",
  5.5: "#81d8a9",
  6: "#a2cf8f",
  7: "#c8b161",
  8: "#e09352",
  9: "#ef6f61",
  10: "#EC4D84",
};

export default invertedSliderColorPalette;