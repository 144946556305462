import useRenderJson from '@/providers/DocumentUiProvider/useRenderJson'
import React from 'react'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonConfig: any
}

const DocumentPage = ({ jsonConfig }: Props) => {
  
  const renderJson = useRenderJson()

  return <>{renderJson(jsonConfig)}</>
}

export default DocumentPage