import queryKeys from "@/constants/queryKeys"
import { privacyPolicyGet, termsAndConditionsGet } from "@/services/api/requests/legal"
import { TLegalDocument } from "@/types/Legal.types"
import { queryOptions } from "@tanstack/react-query"

export const getPrivacyPolicyQuery = () => queryOptions({
  queryKey: [queryKeys.legal.privacyPolicy],
  queryFn: async (): Promise<TLegalDocument> => {
    const document = await privacyPolicyGet();
    return document;
  }
})

export const getTermsOfServiceQuery = () => queryOptions({
  queryKey: [queryKeys.legal.termsOfUse],
  queryFn: async (): Promise<TLegalDocument> => {
    const document = await termsAndConditionsGet();
    return document;
  }
})