import { TMemberCare } from "@/types/MemberCare.types";
import { api } from "..";

const root = 'v1/membercare/organization';

const endpoints = {
  my: `${root}/my/member-cares`,
}



export const getMyOrganizationMemberCares = async (): Promise<TMemberCare[]> => {
  const { data } = await api.get(endpoints.my);  

  const memberCares = data.data as TMemberCare[];

  return memberCares
}