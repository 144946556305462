import useToast from '@/hooks/useToast';
import { Input } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';

type Props = {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  setPreview?: Dispatch<SetStateAction<string | null>>;
  inputRef: React.RefObject<HTMLInputElement>;
  fileSizeLimitInMBs?: number;
  fileMinHeightPx?: number;
  fileMinWidthPx?: number;
  accept?: 'image/*' | '.csv';
};

const MB = 1024 * 1024;

const FilePicker = ({
  inputRef,
  setPreview,
  setSelectedFile,
  fileSizeLimitInMBs = 3,
  fileMinHeightPx = 200,
  fileMinWidthPx = 200,
  accept = 'image/*',
}: Props) => {
  const toast = useToast();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isImage = accept === 'image/*';
      if (isImage) {
        if (file.size > fileSizeLimitInMBs * MB) {
          toast({
            title: 'File too large.',
            description: `File must be less than ${fileSizeLimitInMBs}MB.`,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width < fileMinWidthPx || img.height < fileMinHeightPx) {
            toast({
              title: 'Image dimensions too small.',
              description: `Image must be at least ${fileMinWidthPx}x${fileMinHeightPx}.`,
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
            return;
          }

          if (setPreview) {
            // Generate image preview
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
          }

          // we have to do the setSelectedFile call here, because it's the only spot AFTER img-dimension check
          setSelectedFile(file);
        };
      }

      const isCsv = accept === '.csv';
      if (isCsv) {
        if (file.type !== 'text/csv' || !file.name.endsWith('.csv')) {
          toast({
            title: 'Invalid file type.',
            description: 'Please upload a valid CSV file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setSelectedFile(null);
          return;
        }
        setSelectedFile(file);
      }
    }
  };

  return <Input hidden type="file" accept={accept} ref={inputRef} onChange={handleFileChange} />;
};

export default FilePicker;
