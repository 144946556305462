import { useMutation, useQueryClient } from '@tanstack/react-query';
import useToast from '../useToast';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import { removeMemberFromOrganization } from '@/services/api/requests/organization';
import { TCustomAxiosError } from '@/services/api';
import queryKeys from '@/constants/queryKeys';

const useRemoveMemberFromOrganization = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { trackEvent } = useTelemetry();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberId: string) => {
      const response = await removeMemberFromOrganization(memberId);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.organization.members] });

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member deletion successful!',
      });

      onSuccessCallback?.();

      trackEvent(mixpanelEvents.REMOVE_ORG_MEMBER);

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

export default useRemoveMemberFromOrganization;
