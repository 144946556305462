import Header from '@/components/molecules/Header'
import HeroBackgroundVideo from '@/components/molecules/HeroBackgroundVideo'
import { HEADER_HEIGHT } from '@/constants/dimensions'
import { Flex, Stack, Text, VStack } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

type Props = {
  title?: string
  heroChildren: React.ReactNode
  heroChildrenTopMargin?: number
}

// Just a template for a page with a hero background video
// heroChildren is the children of the hero section - FORM
// children is the children of the page - BELOW VIDEO BACKGROUND
const HeroBackgroundPage = ({ children, title, heroChildren, heroChildrenTopMargin = HEADER_HEIGHT / 2 }: PropsWithChildren<Props>) => {
  return (
    <Flex
      width={'100%'}
      direction={'column'}
      alignItems={'center'}
      top={0}
    >
      <Header variant='transparent' isSticky={false}/>
      <VStack width={'100%'} height={'100%'}>
        <HeroBackgroundVideo />
        <Flex
          flexDirection="column"
          width="100wh"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          position={'absolute'}
          marginTop={{ base: heroChildrenTopMargin, '2xl': 0 }}  
        >
          <Stack flexDir="column" justifyContent="center" alignItems="center">
            <Text
              variant="loraTitle"
              color="white"
              fontSize={"26px"}
            >
              {title}
            </Text>
            {heroChildren}
          </Stack>
        </Flex>
      </VStack>
      {children}
    </Flex>
  )
}

export default HeroBackgroundPage