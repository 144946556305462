import Header from '@/components/molecules/Header'
import { Flex, VStack } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'


const AppPageTemplate = ({children}: PropsWithChildren) => {
  return (
    <Flex
      width={'100%'}
      direction={'column'}
      alignItems={'center'}
    >
      <Header/>
      <VStack marginTop={'40px'}>
        {children}
      </VStack>
    </Flex>
    
  )
}

export default AppPageTemplate