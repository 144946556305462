type ColorObject = {
  [key: number]: string;
};

const sliderColorPalette: ColorObject = {
  1: "#EC4D84",
  2: "#ef6f61",
  3: "#e09352",
  4: "#c8b161",
  5: "#a2cf8f",
  5.5: "#81d8a9",
  6: "#68d093",
  7: "#4fc77c",
  8: "#33be63",
  9: "#16a355",
  10: "#00b547",
};

export default sliderColorPalette;