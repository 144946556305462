import CustomButton from '@/components/atoms/CustomButton';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { TUserMetadata } from '@/types/User.types';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import UserContactDetailsCard from './components/UserContactDetailsBox';
import UserDemographicsCard from './components/UserDemographicsBox';
import UserProfilePhotoBox from './components/UserProfilePhotoBox';

interface UserProfileOverviewProps {
  title: string;
  updateProfilePhoto: (photo: File) => Promise<boolean>;
  userData: TUserMetadata;
  onClickEditContactDetails: () => void;
  onClickEditDemographics: () => void;
  onPhotoChange?: (photo: File) => void;
  onClickProceed?: () => void;
  isLoading?: boolean;
  initialPreview?: string;
  hideEditContactButton?: boolean;
  hideEditDemographicsButton?: boolean;
}

const UserProfileOverview = ({
  title,
  updateProfilePhoto,
  userData,
  onClickEditContactDetails,
  onClickEditDemographics,
  onClickProceed,
  isLoading,
  initialPreview: initialPreviewUrl,
  hideEditContactButton = false,
  hideEditDemographicsButton = false,
}: UserProfileOverviewProps) => {
  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      <Box
        position="absolute"
        backgroundImage={'/img/png/signup-top-right-background.png'}
        backgroundSize="cover"
        width="250px"
        height="30vh"
        right={0}
        top={0}
      ></Box>
      <Text variant={'loraTitle'} fontSize={'26px'} fontStyle={'oblique'} color={'primary.veryDarkBlue'}>
        {title}
      </Text>

      <UserProfilePhotoBox updateProfilePhoto={updateProfilePhoto} initialPreviewUrl={initialPreviewUrl} />

      <HStack gap="40px" marginBottom={'100px'}>
        <UserContactDetailsCard
          email={userData?.email}
          firstName={userData?.firstName}
          lastName={userData?.lastName}
          phone={userData?.phone}
          onClickEdit={onClickEditContactDetails}
          hideEditButton={hideEditContactButton}
        />

        <UserDemographicsCard
          dateOfBirth={userData?.dateOfBirth}
          heightInches={userData?.heightInches}
          heightFeet={userData?.heightFeet}
          weight={userData?.weight}
          sex={userData?.sex}
          sexOther={userData?.sexOther}
          occupation={userData?.occupation}
          occupationOther={userData?.occupationOther}
          ethnicity={userData?.ethnicity}
          ethnicityOther={userData?.ethnicityOther}
          relationshipStatus={userData?.relationshipStatus}
          relationshipStatusOther={userData?.relationshipStatusOther}
          education={userData?.education}
          educationOther={userData?.educationOther}
          onClickEdit={onClickEditDemographics}
          hideEditButton={hideEditDemographicsButton}
        />
      </HStack>

      {onClickProceed && (
        <CustomButton
          backgroundColor="primary.teal"
          label="Proceed"
          width={'300px'}
          labelColor="white"
          onClick={onClickProceed}
          isLoading={isLoading}
        />
      )}
    </VStack>
  );
};

export default UserProfileOverview;
