import { TEulaAcceptance, TRepresentativeFormData } from '@/modules/onboarding/organization/types';
import { TRosterItemPerson } from '@/types/Roster.types';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

type TOnboardingState = {
  step: number;
  isCompleted: boolean;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  // onSubmitRepresentativeForm
  representativeForm: TRepresentativeFormData | null;
  eulaAcceptance: TEulaAcceptance | null,
  setRepresentativeForm: (data: TRepresentativeFormData, eulaAcceptance: TEulaAcceptance | null) => void;
  // onSubmitLogo
  logoFile: File | null;
  logoFilePreview: string | null;
  setLogoFile: (file: File | null, filePreview: string | null) => void;
  // onSubmitRosterTemplate
  rosterCsvFile: File | null;
  rosterFilePreview: string | null;
  rosterParsed: TRosterItemPerson[] | null;
  setRosterFile: (file: File | null, preview: string | null, roster: TRosterItemPerson[] | null) => void;
  
};

const initialState = {
  step: 0,
  isCompleted: false,
  representativeForm: null,
  eulaAcceptance: null,
  logoFile: null,
  logoFilePreview: null,
  rosterCsvFile: null,
  rosterFilePreview: null,
  rosterParsed: null,
};

/**
 * NOTE: Example of a store that requires persistence and subscription.
 * State will persis for user even after page refresh.
 * Use this as a reference when creating a new store.
 */
export const useOrganizationOnboardingStore = create<TOnboardingState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        nextStep: () => {
          set((state) => {
            if (!state.isCompleted) {
              return { ...state, step: state.step + 1 };
            }
            return state;
          });
        },
        prevStep: () =>
          set((state) => {
            if (state.step === 0) {
              return state;
            }
            return { ...state, step: state.step - 1 };
          }),
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
          }));
        },
        setRepresentativeForm: (data: TRepresentativeFormData, eulaAcceptance: TEulaAcceptance | null) => {
          set((state) => ({
            ...state,
            representativeForm: data,
            eulaAcceptance
          }));
        },
        setLogoFile: (file: File | null, filePreview: string | null) => {
          set((state) => ({
            ...state,
            logoFile: file,
            logoFilePreview: filePreview,
          }))
        },
        setRosterFile: (file: File | null, filePreview: string | null, roster: TRosterItemPerson[] | null) => {
          set((state) => ({
            ...state,
            rosterCsvFile: file,
            rosterFilePreview: filePreview,
            rosterParsed: roster,
          }));
        },
      }),
      { name: 'organizationOnboardingStore' },
    ),
  ),
);
