import { ComponentTypes, NodeType } from '@/providers/DocumentUiProvider/types'
import useRenderJson from '@/providers/DocumentUiProvider/useRenderJson'
import { TLegalDocumentAcceptanceStatus } from '@/types/Legal.types'
import { formatDateString } from '@/utils/date'
import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem as ChakraAccordionItem,
  HStack,
  Text,
} from '@chakra-ui/react'
import React from 'react'

type Props = {
  document: TLegalDocumentAcceptanceStatus
}

const EulaUpdateAccordionItem = ({ document }: Props) => {
  const renderJson = useRenderJson()

  const component: NodeType = { type: ComponentTypes.Markdown, children: document?.content }

  const title = document?.type.replaceAll("_", " ")

  return (
    <ChakraAccordionItem
      width={"100%"}
      bg={"white"}
      borderRadius={"10px"}
      marginBottom={"40px"}
      boxShadow={"0px 10px 80px 0px #00417926"}
      borderWidth={0}
    >
      <AccordionButton
        borderWidth={0}
        padding={"40px"}
        justifyContent={"space-between"}
        _hover={{
          background: "white",
          borderRadius: "10px",
        }}
      >
        <HStack width={'100%'}>
          <Text
            variant={"lora"}
            fontWeight={"500"}
            lineHeight={"26px"}
            fontSize={'20px'}
            fontStyle={"oblique"}
          >{title}</Text>
        </HStack>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        paddingLeft={"40px"}
        paddingRight={"40px"}
        paddingBottom={"40px"}
      >
        <HStack>
          <Text color='text.mediumBlue' fontWeight={'700'}>Effective: </Text>
          {/* TODO: EULA - update date to show activatedAt date */}
          <Text color='text.mediumBlue'>{formatDateString({ date: document.updatedAt })}</Text>
        </HStack>
        {renderJson(component)}
      </AccordionPanel>
    </ChakraAccordionItem>
  )
}

export default EulaUpdateAccordionItem