import Heading from "./parts/Heading";
import Link from "./parts/Link";
import List from "./parts/List";
import Paragraph from "./parts/Paragraph";

/**
 * List of html Chakra related components for Markdown
 */

const defaultComponents: Record<string, React.ElementType> = {
  h1: Heading,
  h2: Heading,
  h3: Heading,
  a: Link,
  ol: List,
  ul: List,
  p: Paragraph,
};

export default defaultComponents;
