import { useEffect, useMemo } from "react";

import * as d3 from "d3";

import colorConsts from '@/theme/colors';
import { Flex, HStack, Text, VStack } from "@chakra-ui/react";

export type WellnessItemType = "work" | "mental" | "physical";

export type TChartDataItem = {
  name: string;
  value: number;
  itemType?: WellnessItemType;
};

interface ChartColorItem {
  name: string;
  value: string;
}

const CHART_WIDTH = 220;
const MAX_CHART_WIDTH = 500;

const renderChart = (
  width = CHART_WIDTH,
  data: TChartDataItem[],
  colors: ChartColorItem[],
) => {
  const height = Math.min(width, MAX_CHART_WIDTH);
  const radius = Math.min(width, height) / 2;

  const arc = d3
    .arc<d3.PieArcDatum<TChartDataItem>>()
    .innerRadius(radius * 0.67)
    .outerRadius(radius - 1)
    .cornerRadius(10);

  const pie = d3
    .pie<TChartDataItem>()
    .value((d) => d.value)
    .padAngle(0.03);

  const color = colors
    ? (itemName: string) =>
        colors.find((color: ChartColorItem) => color.name === itemName)?.value
    : d3
        .scaleOrdinal()
        .domain(data.map((d) => d.name))
        .range(
          d3
            .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
            .reverse(),
        );

  const theChartElement = d3.select("#chart-container-id");
  theChartElement.selectChildren().remove(); // to make sure we always have just one chart rendered

  const svg = theChartElement
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .attr("style", "max-width: 100%; height: auto;");

  svg
    .append("g")
    .selectAll()
    .data(pie(data))
    .join("path")
    .attr("fill", (d) => color(d.data.name) as string)
    .attr("d", arc)
    .append("title") // this adds the hover tooltip
    .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}%`);

  return svg.node();
};

const DoughnutChart = ({
  data,
  colors,
  hasLegend = true,
}: {
  data: TChartDataItem[];
  colors?: ChartColorItem[];
  hasLegend?: boolean;
}) => {
  const finalColors = useMemo(() => {
    if (colors) return colors;
    let i = data.length;
    const generatedColors: ChartColorItem[] = [];
    const opacityStep = Math.ceil((1 / (data.length + 1)) * 255);
    while (i > 0) {
      generatedColors.push({
        name: data[data.length - i].name,
        value: `${colorConsts.primary[500]}${(opacityStep * i).toString(16)}`,
      });
      i--;
    }
    return generatedColors;
  }, [data, colors]);

  useEffect(() => {
    let i = data.length;
    const generatedColors: ChartColorItem[] = [];
    const opacityStep = Math.ceil((1 / (data.length + 1)) * 255);
    while (i > 0) {
      generatedColors.push({
        name: data[data.length - i].name,
        value: `${colorConsts.primary[500]}${(opacityStep * i).toString(16)}`,
      });
      i--;
    }
    renderChart(CHART_WIDTH, data, finalColors);
  }, [data, finalColors]);

  return (
    <HStack gap={"40px"} marginTop={"20px"}>
      <Flex
        background="white"
        borderRadius={"50%"}
        padding={"43px"}
        boxShadow={"0px 6px 34px 0px #00417933"}
      >
        <div id="chart-container-id"></div>
      </Flex>
      {hasLegend && (
        <VStack>
          {data.map((item) => (
            <HStack key={item.name} width={"100%"}>
              <Flex
                borderRadius={"50%"}
                width={"20px"}
                height={"20px"}
                background={
                  finalColors?.find((color) => color.name === item.name)?.value
                }
              ></Flex>
              <Text
                fontSize={20}
                color={"text.blueGray"}
                fontWeight={600}
                marginRight={"5px"}
              >
                {item.name}
              </Text>
              {/* {itemTypeToIcon(item.itemType)} */}
            </HStack>
          ))}
        </VStack>
      )}
    </HStack>
  );
};

export default DoughnutChart;
