import { APP_NAME } from '@/constants/stringVars';

const authMessages = {
  signInTitle: `Welcome to ${APP_NAME} - a wholeness platform.`,
  signInDescription: `We invite you to join us and let ${APP_NAME} help your organization with everything related to mental health.`,
  email: 'Email',
  password: 'Password',
  repeatPassword: 'Repeat Password',
  signIn: 'Sign In',
  contactSupport: 'Contact Support',
  updatePassword: 'Update Password',
  forgotPassword: 'Forgot Password?',
  forgotPwDescriptionOne:
    'No worries! Just enter your email address below, and we’ll send you a link to reset your password.',
  forgotPwDescriptionTwo:
    'If you don’t receive an email, please check your spam folder or try entering your email address again.',
  submit: 'Submit',
  goBack: 'Go back',
  deactivateAccount: 'Deactivate Account',
  deactivateAccountConfirm: 'Are you sure you want to deactivate your account?',
  deactivateAccountDescription: 'This action will sign you out.',
};

export default authMessages;
