import { CALENDLY_ROOT_ELEMENT_ID } from "@/constants/stringVars";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { PopupModal } from "react-calendly";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  shareUrl: string;
};

const CalendlyModal = ({ isOpen = false, setIsOpen, shareUrl }: Props) => {
  const [rootElement, setRootElement] = useState<any>(null);

  useEffect(() => {
    // Wait for the component to be mounted before setting the rootElement
    if (typeof window !== "undefined") {
      setRootElement(document.getElementById(CALENDLY_ROOT_ELEMENT_ID));
    }
  }, []);

  return (
    <PopupModal
      url={shareUrl}
      open={isOpen}
      onModalClose={() => setIsOpen(false)}
      rootElement={rootElement}
    />
  );
};

export default CalendlyModal;