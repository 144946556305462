import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { useAuthContext } from '@/context/auth/useAuthContext';
import { useDeactivateOwnAccount } from '@/hooks/auth/deactivateOwnAccount';
import appMessages from '@/messages';
import menuMessages from '@/messages/menu';
import { HStack, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const Settings = () => {
  const onDeactivateAccountSuccess = () => {
    signOut();
    navigate({
      to: '/sign-in',
    });
  };

  const { mutate, isPending } = useDeactivateOwnAccount(onDeactivateAccountSuccess);
  const navigate = useNavigate();
  const { signOut } = useAuthContext();

  // TODO: will be added later
  // const {
  //   isOpen: isChangePasswordModalOpen,
  //   onOpen: openChangePasswordModal,
  //   onClose: closeChangePasswordModal,
  // } = useDisclosure();

  const {
    isOpen: isDeactivateAccountModalOpen,
    onOpen: onDeactivateAccountModalOpen,
    onClose: onDeactivateAccountModalClose,
  } = useDisclosure();

  const deactivateUserAccount = () => {
    mutate();
    onDeactivateAccountModalClose();
  };

  return (
    <AppPageTemplate>
      <VStack>
        <Text variant={'loraSmallTitle'}>{menuMessages.settings}</Text>
        <HStack minWidth={'700px'} justifyContent={'center'} marginTop={'50px'}>
          {/* TODO: will be added later */}
          {/* <VStack>
            <Image
              alt="lock-password"
              src={iconsPng.lockPassword}
              width={140}
              height={140}
              style={{ margin: '30px 0px' }}
            />
            <CustomButton
              disabled
              backgroundColor="primary.500"
              label={appMessages.updatePassword}
              width={FORM_FIELD_WIDTH}
              height={'55px'}
            />
          </VStack> */}
          <VStack>
            <Image
              alt="user-deactivate"
              src={iconsPng.userDeactivate}
              width={140}
              height={140}
              style={{ margin: '30px 0px' }}
            />
            <CustomButton
              backgroundColor="extra.red"
              labelColor="extra.white"
              label={appMessages.deactivateAccount}
              onClick={onDeactivateAccountModalOpen}
              width={FORM_FIELD_WIDTH}
              height={'55px'}
              labelStyle={{ fontWeight: 700 }}
              isLoading={isPending}
            />
            <ConfirmationModal
              confirmButtonText="Deactivate"
              description={appMessages.deactivateAccountDescription}
              isOpen={isDeactivateAccountModalOpen}
              onClose={onDeactivateAccountModalClose}
              onConfirm={deactivateUserAccount}
              title={appMessages.deactivateAccountConfirm}
              confirmButtonColor="extra.red"
            />
          </VStack>
        </HStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/settings/')({
  component: Settings,
});
