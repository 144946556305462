import queryKeys from "@/constants/queryKeys";
import { assessmentBySlugGet } from "@/services/api/requests/assessments";
import { queryOptions } from "@tanstack/react-query";

export const getWholenessScreenerQuery = () => queryOptions({
  queryKey: [queryKeys.documents.screener, 'wholeness-screener'],
  queryFn: async () => {
    const document = await assessmentBySlugGet('wholeness-screener');
    return document
  },
})