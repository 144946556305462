
import DocumentPage from '@/components/organisms/DocumentPage';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import useDocumentById from '@/hooks/documents/useDocumentById';
import { useAppStore } from '@/store/useAppStore';
import { isUndefined } from '@/utils';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { Spinner } from '@chakra-ui/react';
import { createFileRoute, redirect } from '@tanstack/react-router'

const ScreenerPage = () => {
  const { screenerId  } = Route.useParams()

  const { isLoading, data, isSuccess } = useDocumentById(screenerId)

  return <AppPageTemplate>
    {isLoading &&<Spinner color='primary.500' />}
    {(!isUndefined(data) && isSuccess) &&
      <DocumentPage jsonConfig={data.data}/>
    }
    
  </AppPageTemplate>
}

export const Route = createFileRoute('/_authenticated/screener/$screenerId')({
  component: ScreenerPage,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const { onboardingStatuses, isEulaAccepted } = useAppStore.getState()

    const isOnboardingCompleted = isEverythingSetup(onboardingStatuses, isOrgAdmin)

    if (!isOnboardingCompleted) {
      if (isOrgAdmin) {
        throw redirect({
          to: '/onboarding/organization',
        });
      }

      throw redirect({
        to: '/onboarding/user',
      });
    }

    if (!isEulaAccepted) {
      throw redirect({
        to: '/eula/update',
      });
    }
  }
})
