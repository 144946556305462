import React from 'react'

import svgFiles from '@/assets/svg/svgFiles'
import { USER_PROFILE_AVATAR_SIZE } from '@/constants/dimensions'
import { Center, Image, VStack, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useAppStore } from '@/store/useAppStore'


type Props = {
  isOpen?: boolean
  isHeaderTransparent?: boolean
}

const MenuPlaceholder = ({isOpen = false, isHeaderTransparent = false }: Props) => {
  const { user } = useAppStore()

  const textColor = isHeaderTransparent ? 'white' : 'text.mediumGray'

  return (
    <Center height={'100%'}>
      <Image
        objectFit={'cover'}
        src={user?.user_metadata.profilePicture ?? svgFiles.genericUser}
        alt='profile-image'
        width={USER_PROFILE_AVATAR_SIZE}
        height={USER_PROFILE_AVATAR_SIZE}
        style={{
          borderRadius: "50%",
          border: "2px solid white",
          minHeight: `${USER_PROFILE_AVATAR_SIZE}px`,
          minWidth: `${USER_PROFILE_AVATAR_SIZE}px`,
          background: user?.user_metadata.profilePicture
            ? "transparent"
            : "lightgray",
          boxShadow: "0px 6px 12px 0px #0E006224",
        }}
      />
      <VStack flexGrow={1} alignItems={"flex-start"} marginLeft={"10px"}>
        <Text
          fontSize={"14px"}
          lineHeight={"20px"}
          color={textColor}
          opacity={isOpen ? 0.7 : 1}
          paddingX={"5px"}
          fontWeight={800}
        >
          {user?.user_metadata.firstName}
        </Text>
      </VStack>
      {isOpen ? (
        <ChevronUpIcon boxSize={"5"} color={textColor} opacity={isOpen ? 0.7 : 1} />
      ) : (
          <ChevronDownIcon boxSize={"5"} color={textColor} />
      )}
    </Center>
  )
}

export default MenuPlaceholder