import React, { useState } from 'react'
import onboardingMessages from '@/messages/onboarding'
import { Button, Center, Text } from '@chakra-ui/react'
import DrawnCirclesBackground from '@/components/atoms/DrawnCirclesBackground'
import FilePicker from '@/components/atoms/FilePicker'
import OrgOnboardingPage from './OrgOnboardingPage'
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore'

type Props = {
  onContinue?: (file: File, preview: string) => void
  onGoBack?: (file: File | null, preview: string | null) => void
}

const LogoUpload = ({ onContinue = () => { }, onGoBack = () => { } }: Props) => {
  const imageRef = React.useRef<HTMLInputElement>(null)

  const { logoFile, logoFilePreview } = useOrganizationOnboardingStore()

  const [preview, setPreview] = useState<string | null>(logoFilePreview ?? null);
  const [selectedFile, setSelectedFile] = useState<File | null>(logoFile ?? null);

  const triggerFilePicker = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  }

  return (
    <OrgOnboardingPage
      title={onboardingMessages.uploadCompanyLogoTitle}
      subTitle={onboardingMessages.uploadCompanyLogoSubtitle}
      description={onboardingMessages.uploadCompanyLogoDescription}
      onGoBack={() => {
        onGoBack(selectedFile, preview ?? '')
      }}
      onSubmit={() => {
        if (selectedFile) {
          onContinue(selectedFile, preview ?? '');
        }
      }}
      isSubmitDisabled={!selectedFile}
      >
      <FilePicker
        inputRef={imageRef}
        setPreview={setPreview}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
      <Button variant={'continue'} marginY={'54px'} onClick={triggerFilePicker}>
        {selectedFile? onboardingMessages.uploadDifferentLogo : onboardingMessages.uploadLogo}
      </Button>
      <DrawnCirclesBackground>
        <Center
          width={300}
          height={300}
          borderRadius={"50%"}
          backgroundImage={preview ?? ''}
          backgroundPosition={"center"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
          boxShadow={"0px 7.5px 90px 0px #4E1AEF36"}
          backgroundColor={"extra.white"}
        >
          <Text fontWeight={500} fontSize={"16px"} color={"text.mediumGray"}>
            {preview ? null : onboardingMessages.previewWillBeShownHere}
          </Text>
        </Center>
      </DrawnCirclesBackground>
    </OrgOnboardingPage>
  )
}

export default LogoUpload