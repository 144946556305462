export const MP_ORG_SIGN_UP_DONE = 'org_sign_up_done';
export const MP_USER_DEMOGRAPHICS_ONBOARDING_DONE = 'user_demographics_done';
export const MP_USER_SIGN_UP_DONE = 'user_sign_up_done';
export const MP_SCREENER_START = 'screener_start';
export const MP_SCREENER_FINISH = 'screener_finish';
export const MP_MEMBER_CARE_CALL_SCHEDULED = 'member_care_call_scheduled';
export const MP_ADD_ORG_MEMBER = 'add_org_member';
export const MP_REINVITE_ORG_MEMBER = 'reinvite_org_member';
export const MP_REMOVE_ORG_MEMBER = 'remove_org_member';
export const MP_EULA_ACCEPTED = 'eula_accepted';

export const mixpanelEvents = {
  ORG_SIGN_UP_DONE: MP_ORG_SIGN_UP_DONE,
  USER_DEMOGRAPHICS_ONBOARDING_DONE: MP_USER_DEMOGRAPHICS_ONBOARDING_DONE,
  USER_SIGN_UP_DONE: MP_USER_SIGN_UP_DONE,
  SCREENER_START: MP_SCREENER_START,
  SCREENER_FINISH: MP_SCREENER_FINISH,
  MEMBER_CARE_CALL_SCHEDULED: MP_MEMBER_CARE_CALL_SCHEDULED,
  ADD_ORG_MEMBER: MP_ADD_ORG_MEMBER,
  REINVITE_ORG_MEMBER: MP_REINVITE_ORG_MEMBER,
  REMOVE_ORG_MEMBER: MP_REMOVE_ORG_MEMBER,
  EULA_ACCEPTED: MP_EULA_ACCEPTED,
};
