import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { queryClient } from './services/queryClient';
import { useAuthContext } from './context/auth/useAuthContext';
import LoadingPage from './components/templates/LoadingPage';

const router = createRouter({
  routeTree: routeTree,
  context: {
    auth: null,
    queryClient,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: () => <LoadingPage/>,
});
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const App = () => {
  const auth = useAuthContext();

  return (
    <RouterProvider
      router={router}
      defaultPreload="intent"
      context={{
        queryClient,
        auth,
      }}
    />
  );
};

export default App;
