import queryKeys from "@/constants/queryKeys"
import { assessmentBySlugGet } from "@/services/api/requests/assessments";
import { useQuery } from "@tanstack/react-query"

const useDocumentById = (id: string) => {
  const { isLoading, data, isError, isSuccess } = useQuery({
    queryKey: [queryKeys.documents.screener, id],
    queryFn: async () => {
      const document = await assessmentBySlugGet(id);

      return document
    }
  })

  return { isLoading, data, isError, isSuccess }

}

export default useDocumentById