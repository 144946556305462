import { useMemo } from "react"
import renderJson from "./renderJson"
import useDocumentUi from "./useDocumentUi"
import { NodeType } from "./types"

const useRenderJson = () => {
  const { components } = useDocumentUi()
  const renderJsonInstance = useMemo(() => (json: NodeType) => renderJson(json, components), [components])

  return renderJsonInstance
}

export default useRenderJson