import { MAX_LINES_IN_ROSTER } from '@/constants';
import { APP_NAME } from '@/constants/stringVars';

const onboardingMessages = {
  userOnboardingTitle: `Welcome to ${APP_NAME} - a wholeness platform.`,
  userOnboardingDescription: `We invite you to join us and let ${APP_NAME} help your organization with everything related to mental health.`,
  proceed: 'Proceed',
  yourEmailAddress: 'Your email address',
  iAgreeTo: 'I agree to',
  termsOfUse: 'Terms of Use',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  termsOfInfoTooltip: `I agree that I have read and agree to the User Agreement that outlines what products, services, and features are available to me. Along with the Data Use, Privacy Policy and HIPAA disclosure. \n${APP_NAME} is a software provider, and will operate as an associate of various providers as we coordinate the care management efforts along your journey to wholeness.`,
  tellUsMore: 'Tell us a bit more about yourself',
  tellUsMoreDescription: `We’re about to ask you for your personal information. Your personal information will always be kept 100% confidential from your employer. We understand your apprehension in providing personal information, however, it is necessary to assure you have a seamless experience regarding access to, and payment of, therapy and wellness support. Rest assured we are HIPAA compliant and have all the required firewalls and protocols to keep your information protected and secure.`,
  organizationOnboardingDescription: `We invite you to join us and let ${APP_NAME} help your organization with everything related to mental health.`,
  uploadCompanyLogoTitle: 'Upload Company Logo',
  uploadCompanyLogoSubtitle: "Use your brand to customize your team's experience.",
  uploadCompanyLogoDescription: 'File requirements - Use a square image at least 200x200 pixels, 3MB or less.',
  uploadLogo: 'Upload Logo',
  uploadDifferentLogo: 'Upload Different Logo',
  previewWillBeShownHere: 'Preview will be shown here',
  goBack: 'Go back',
  continue: 'Continue',
  createYourRosterTitle: 'Create your Roster',
  createYourRosterSubtitle: 'Use our template to easily add all your organization members.',
  createYourRosterDescription: 'Make sure you follow these simple steps to upload CSV correctly.',
  downloadOurRosterTemplate: 'Download our Roster Template (CSV)',
  downloadRosterTemplate: 'Download Roster Template',
  downloadRosterTemplateDescription:
    'Download the Template and Fill out the Roster without adjusting any of the columns. Be sure to fill in each column for each user to prevent errors.',
  uploadYourCompletedRoster: 'Upload Your Completed Roster',
  uploadYourRosterDescription: `Now that you've modified the template with your team's info, you're ready to upload it here!`,
  uploadCSV: 'Upload CSV',
  previewYourRosterTitle: 'Preview your Roster',
  previewYourRosterSubtitle: 'You can see how we loaded your data, make sure it all looks good.',
  previewYourRosterDescription: 'If there are any errors make sure you go back and upload new CSV.',
  tooManyRecordsInRosterFile: `There are too many records in the roster file. Please add a maximum of ${MAX_LINES_IN_ROSTER} people.`,
  looksGood: 'Looks good, finish setup!',
  eulaNotAcceptedProperly: 'EULA not accepted properly. Please re-check.',
  emailSuccessfullyVerified: 'You have successfully verified your email.',
  emailNotVerifiedSuccessfully: 'Wrong credentials or expired token',
  resetPassword: 'Reset Your Password',
  pleaseEnterNewPasswordBelow: 'Please enter your new password below.',
  checkEmailOrContactAdmin: 'Please check your email or contact your administrator.',
  passwordSetupUnsuccessful: 'Password setup unsuccessful.',
  createYourPassword: 'Next, create your password.',
  passwordMustContain: 'Password must contain:',
  password8chars: '8+ characters',
  passwordLowercase: '1 lowercase',
  passwordUppercase: '1 uppercase letter',
  passwordNumberOrSpecialChar: '1 number or special character',
  listItemDot: '•',
};

export default onboardingMessages;
