import videoMaterials from '@/assets/video';
import { Image, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

type Props = {
  url?: string;
  width?: string;
  height?: string;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  autoPlay?: boolean;
  poster?: string;
};

const VideoPlayer = ({
  url = videoMaterials.manMeditating,
  poster = videoMaterials.manMeditatingPoster,
  controls = false,
  width = '100%',
  height = '100%',
  loop = true,
  muted = true,
  autoPlay = true,
}: Props) => {
  const [isPlaying] = React.useState(autoPlay);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleOnReady = () => {
    setIsVideoReady(true);
  };

  return (
    <div style={{ zIndex: -1 }}>
      {!isVideoReady && <Image src={poster} alt="video-poster" style={{ width: '100vw', height: '98vh' }} />}
      <VStack>
        <ReactPlayer
          url={url}
          controls={controls}
          muted={muted}
          pip
          width={width}
          height={height}
          loop={loop}
          playing={isPlaying}
          onReady={handleOnReady}
          config={{
            file: {
              attributes: {
                preload: 'auto',
              },
            },
          }}
          style={{ height, width }}
        />
      </VStack>
    </div>
  );
};

export default VideoPlayer;
